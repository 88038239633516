import { cva } from 'class-variance-authority'

export { default as Textarea } from './Textarea.vue'

export const textareaVariants = cva(
  'flex min-h-[80px] w-full rounded-md border bg-white px-3 py-2 text-sm ring-offset-white placeholder:text-gray-500 focus-visible:outline-none disabled:cursor-not-allowed disabled:opacity-50',
  {
    variants: {
      variant: {
        default:
          'focus-visible:ring-primary-400 border-primary-200 placeholder:text-gray-500 focus-visible:ring-2 focus-visible:ring-offset-2',
        error: 'border-red-500 placeholder:text-red-200 bg-red-50'
      }
    },
    defaultVariants: {
      variant: 'default'
    }
  }
)
