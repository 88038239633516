import tailwindColors from 'tailwindcss/colors'

import colors from '@/lib/utils/colors'
import { mimeTypes } from '@/lib/utils/constants'

import { IMAGE_EXTENSIONS, SUPPORTED_FILE_EXTENSIONS } from '@/capability/document/types'
import type { DocumentDto } from 'typescript-core-api-client'

import type { buttonVariants } from '@/component/arqu-components/shadcn/ui/button'

export function getDocumentName(id: string, documents: DocumentDto[]): string {
  if (id === 'arqu_sov') return 'Arqu SOV'
  if (id === 'coverage_summary') return 'Coverage Summary'
  if (id === 'marketing_activity') return 'Marketing Activity'
  return (documents ?? []).find((d: DocumentDto) => d.id === id)?.name ?? ''
}

export const getDocumentExtension = (item: DocumentDto): string => getExtensionByName(item.name ?? '')

export function getExtensionByName(name: string): string {
  const lastIndex = name.lastIndexOf('.')
  if (lastIndex) {
    if (name.slice(lastIndex + 1)) {
      return `.${name.slice(lastIndex + 1).toLowerCase()}`
    }
    return ''
  }
  return ''
}

export const DOCUMENT_NAME_PATTERN = /^[a-zA-Z0-9_ ,()-]+$/

export const isExtractable = (item: DocumentDto) =>
  item?.documentType?.name === 'ArquSOV' ||
  item?.documentType?.name === 'ArquLossSummary' ||
  item?.documentType?.name === 'ArquPropertyLossSummary' ||
  item?.documentType?.name === 'ArquCasualtyLossSummary' ||
  item?.documentType?.name === 'ArquEnvironmentalLossSummary'

export function displayFileType(mimeType: string): string {
  const fileType = mimeTypes.find((fileType) => fileType.type === mimeType)
  return fileType ? fileType.label : 'Unknown'
}

export const isDocumentUncategorized = (item: DocumentDto, modelValue: string): boolean =>
  item.documentType?.name === 'Unknown' && item.documentTypeSuggestion?.name === 'Unknown' && modelValue === 'Unknown'

export const isDocumentSuggested = (item: DocumentDto, modelValue: string): boolean =>
  item.documentType?.name === 'Unknown' &&
  item.documentTypeSuggestion?.name !== 'Unknown' &&
  modelValue === item.documentTypeSuggestion?.name

export function getDocumentTypePrependIcon(item: DocumentDto, modelValue: string): string {
  if (isDocumentSuggested(item, modelValue)) {
    return 'lucide:lightbulb'
  }
  return ''
}

export function getDocumentTypeVariant(
  item: DocumentDto,
  modelValue: string
): NonNullable<Parameters<typeof buttonVariants>[0]>['variant'] {
  if (isDocumentUncategorized(item, modelValue)) {
    return 'destructive-outline'
  }
  if (isDocumentSuggested(item, modelValue)) {
    return 'yellow-outline'
  }
  return 'outline'
}

export function getDocumentTypeStyle(item: DocumentDto, modelValue: string): { border: string } {
  let color = colors.primary['200']
  if (isDocumentUncategorized(item, modelValue)) {
    color = tailwindColors.red['500']
  } else if (isDocumentSuggested(item, modelValue)) {
    color = tailwindColors.yellow['500']
  }
  return { border: `1px solid ${color} !important` }
}

export function getDocumentTabTooltip(name: string) {
  switch (name) {
    case 'Marketing':
      return 'Marketing: COPE, project details, specs and applications'
    case 'Loss Runs':
      return 'Loss Runs: Carrier loss runs and NKLLs'
    case 'Policy Information':
      return 'Policy Information: Policy docs, endorsements and carrier notices'
    case 'Claims':
      return 'Claims: FNOLs, SOLs and other claim-specific documentation or correspondence'
    case 'Inspections':
      return 'Inspection reports and correspondence'
    case 'Quote Docs':
      return 'Marketing documents received, being sent to client'
    case 'Binding Subjectivities':
      return 'Information needed by Carriers'
    case 'Working Docs':
      return 'Working Docs: arqu templates and ideation resources'
    default:
      return ''
  }
}

export function documentNotShareable(doc: DocumentDto): boolean {
  const unShareableFolders = ['Uncategorized', 'Working Docs']
  if (doc.folderName) {
    return unShareableFolders.includes(doc.folderName)
  }
  if (doc.documentType?.folderName) {
    return unShareableFolders.includes(doc.documentType.folderName)
  }
  return false
}

export function getHref(link: string, extension: string): string {
  if (IMAGE_EXTENSIONS.includes(extension)) {
    return link
  } else {
    return `https://docs.google.com/gview?url=${encodeURIComponent(link)}`
  }
}

export const previewDisabled = (doc: DocumentDto): boolean =>
  !SUPPORTED_FILE_EXTENSIONS.some((ext) => doc?.originalName?.toLowerCase().endsWith(ext))
