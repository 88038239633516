<script setup lang="ts">
import type { PropType } from 'vue'
import { computed, ref } from 'vue'

import { cn, twMerge } from '@/lib/utils'
import { truncateText } from '@/lib/utils/formatting'

import type { ExposureType } from '@/capability/program/constants'

import InputDetails from '@/component/arqu-components/inputs/InputDetails.vue'
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuTrigger
} from '@/component/arqu-components/shadcn/ui/dropdown-menu'
import { Label } from '@/component/arqu-components/shadcn/ui/label'
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/component/arqu-components/shadcn/ui/tooltip'

const props = defineProps({
  modelValue: {
    type: Array as PropType<String[]>,
    required: false,
    default: () => []
  },
  items: {
    type: Array as PropType<ExposureType[]>,
    required: false,
    default: () => []
  },
  errors: {
    type: Array as PropType<String[]>,
    required: false,
    default: () => []
  }
})

const emit = defineEmits<{
  (e: 'update:modelValue', payload: string[]): void
}>()

const TRUNCATE_LENGTH = 48

const open = ref<boolean>(false)
function handleOpen(e: boolean) {
  if (e) {
    open.value = e
  }
}

const exposures = computed({
  get(): string[] {
    return props.modelValue as string[]
  },
  set(newValue: string[]): void {
    emit('update:modelValue', newValue)
  }
})

const displayText = computed(() => {
  if (!exposures.value.length) {
    return 'Select Multiple'
  }
  return props.items
    .filter((i) => !!i.name && exposures.value.includes(i.name))
    .map((i) => i.title)
    .join(', \n')
})

function isSelected(exposureName: string): boolean {
  return exposures.value.includes(exposureName)
}

function handleSelect(exposureName: string) {
  if (isSelected(exposureName)) {
    exposures.value = exposures.value.filter((d) => d !== exposureName)
  } else {
    exposures.value = [...exposures.value, exposureName]
  }
}
</script>

<template>
  <div>
    <Label>Select Exposure</Label>
    <DropdownMenu :open="open" @update:open="handleOpen">
      <DropdownMenuTrigger as-child>
        <rq-btn
          id="exposure-type-select"
          :class="twMerge('flex w-full items-center justify-between', exposures.length ? 'text-gray-950' : 'text-gray-500')"
          datacy="select-exposure"
          :disabled="!items.length"
          :style="{ border: '1px solid rgb(229 231 235) !important' }"
          variant="outline"
        >
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger as-child>
                <span>{{ truncateText(displayText, TRUNCATE_LENGTH) }}</span>
              </TooltipTrigger>
              <TooltipContent align="start" class="max-w-[400px] flex-wrap">{{ displayText }}</TooltipContent>
            </Tooltip>
          </TooltipProvider>
          <rq-icon icon="lucide:chevron-down" />
        </rq-btn>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="start" :class="cn('max-h-64 w-full overflow-y-auto')" @interact-outside="open = false">
        <DropdownMenuCheckboxItem
          v-for="item in items"
          :key="item.name"
          :checked="isSelected(item.name as string)"
          :datacy="`listbox-item-${item.title}`"
          :value="item.name"
          @click.prevent="handleSelect(item.name as string)"
        >
          {{ item.title }}
        </DropdownMenuCheckboxItem>
      </DropdownMenuContent>
    </DropdownMenu>
    <InputDetails v-if="errors.length > 0" id="selected-exposure-type-errors" :errors="errors" />
  </div>
</template>

<style scoped lang="scss"></style>
