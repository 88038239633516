<script lang="ts">
import type { IndicationMarketModel } from '@/capability/indication/model'

export type Props = {
  market: IndicationMarketModel
  location?: 'menu' | 'dialog'
  dialog?: boolean
}
</script>

<script setup lang="ts">
import { watch } from 'vue'
import { ref } from 'vue'
import { useAcl } from 'vue-simple-acl/src'
import sortBy from 'lodash.sortby'
import { twMerge } from 'tailwind-merge'

import { useNotificationStore } from '@/stores/notification'

import type { AMBestCompanyModel } from '@/capability/ambest/types'
import { indicationService } from '@/capability/indication/service'
import { insCipherService } from '@/capability/inscipher/service'
import type { OrganizationModel } from '@/capability/organization/model'
import { organizationService } from '@/capability/organization/OrganizationService'

import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger
} from '@/component/arqu-components/shadcn/ui/dialog'
import ProgramIndicationAMBestCompanySelector from '@/component/program/program-indications/program-indications-menu/ProgramIndicationAMBestCompanySelector.vue'

const props = withDefaults(defineProps<Props>(), {
  location: 'dialog',
  dialog: false
})

const emit = defineEmits<{
  (e: 'update:market', market: IndicationMarketModel): void
  (e: 'update:dialog', value: boolean): void
  (e: 'close'): void
}>()

const acl = useAcl()
const notificationStore = useNotificationStore()

const issuingCompanyName = ref<string>('')
const amBestCompany = ref<AMBestCompanyModel | undefined>()

const organization = ref<OrganizationModel>()

const crossReferenceCss = ref('text-sm text-green-500')
const crossReferenceCheck = ref('')

watch(amBestCompany, async (value) => {
  if (value) {
    if (!issuingCompanyName.value) {
      issuingCompanyName.value = value.companyName!
    }
    const insCipherCompany = await insCipherService.getByNAIC(value.naic!)
    if (insCipherCompany) {
      crossReferenceCheck.value = `Found matching insCipher active Company with NAIC ${value.naic} with Company Name ${insCipherCompany?.name}`
    } else {
      crossReferenceCss.value = 'text-sm text-red-500'
      crossReferenceCheck.value = `No matching insCipher active Company found with NAIC ${value.naic}`
    }
  }
})

async function addIssuingCompany(companyName: string) {
  try {
    if (organization.value) {
      organization.value.issuingCompanies = organization.value.issuingCompanies ?? []
      if (!organization.value.issuingCompanies.includes(companyName)) {
        organization.value.issuingCompanies.push(companyName)
        await organizationService.update(organization.value)
      }
    }
    issuingCompanyName.value = companyName
    notificationStore.publishSuccessMessage('Issuing company added')
  } catch (e) {
    notificationStore.publishOneOrMoreErrUnhandled(e as unknown as Error)
  }
}

async function assignIssuingCompany() {
  try {
    const market = {
      ...props.market,
      issuingCompanyName: issuingCompanyName.value,
      amBestCompany: amBestCompany.value
    } as IndicationMarketModel
    await indicationService.updateMarket({ market })
    notificationStore.publishSuccessMessage('Issuing company assigned')
    open.value = false
    if (props.location === 'menu') {
      emit('update:market', { ...market, version: (market.version ?? 0) + 1 })
    }
  } catch (e) {
    notificationStore.publishOneOrMoreErrUnhandled(e as unknown as Error)
  }
}

async function clearIssuingCompany() {
  try {
    const market = { ...props.market, issuingCompanyName: '', amBestCompany: undefined } as IndicationMarketModel
    await indicationService.updateMarket({ market })
    notificationStore.publishSuccessMessage('Issuing company cleared')
    open.value = false
    if (props.location === 'menu') {
      emit('update:market', { ...market, version: (market.version ?? 0) + 1 })
    }
  } catch (e) {
    notificationStore.publishOneOrMoreErrUnhandled(e as unknown as Error)
  }
}

const open = ref<boolean>(props.dialog)

watch(open, async (value) => {
  if (value) {
    issuingCompanyName.value = props.market?.issuingCompanyName ?? ''
  } else {
    emit('close')
  }
})

watch(
  () => props.market,
  async (value) => {
    issuingCompanyName.value = value?.issuingCompanyName ?? ''
    amBestCompany.value = value?.amBestCompany
    if (value?.marketId) {
      organization.value = await organizationService.getById({ organizationId: value?.marketId! })
    }
  },
  { immediate: true }
)
</script>

<template>
  <Dialog v-model:open="open">
    <DialogTrigger as-child>
      <button
        v-if="location === 'menu'"
        class="w-full px-4 py-2.5 text-left hover:bg-gray-200 disabled:text-gray-400 disabled:hover:bg-white"
      >
        Assign Issuing Company
      </button>
      <rq-btn v-else variant="primary-outline">Assign Issuing Company for {{ market?.marketName }}</rq-btn>
    </DialogTrigger>
    <DialogContent class="p-4">
      <DialogHeader>
        <DialogTitle>{{ market?.marketName }}</DialogTitle>
        <DialogDescription>Choose an issuing company or add a new one</DialogDescription>
      </DialogHeader>
      <div class="space-y-2 px-1">
        <rq-combobox-single
          v-model="issuingCompanyName"
          :items="sortBy(organization?.issuingCompanies || [])"
          label="Pick an Issuing Company from the previous selection"
        />
      </div>
      <div class="space-y-2 px-1">
        <ProgramIndicationAMBestCompanySelector v-model="amBestCompany" :search="issuingCompanyName" />
      </div>
      <div :class="twMerge('space-y-2 px-1', crossReferenceCss)">
        {{ crossReferenceCheck }}
      </div>
      <DialogFooter class="mt-4 flex justify-end">
        <rq-btn variant="outline" @click="open = false">Close</rq-btn>
        <rq-btn :disabled="!issuingCompanyName" variant="primary" @click="assignIssuingCompany">Update</rq-btn>
        <rq-btn :if="acl.anyRole(['admin'])" variant="gray" @click="clearIssuingCompany">Clear</rq-btn>
      </DialogFooter>
    </DialogContent>
  </Dialog>
</template>

<style scoped></style>
