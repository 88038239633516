/**
 * Logger utility that provides console logging and Datadog integration.
 *
 * Usage:
 * ```ts
 * import { getLogger } from '@/composables/util/log/logger'
 *
 * // Standard logging
 * logger.info('Operation started', {
 *   context: { method: 'processData' }
 * })
 *
 * // Error handling
 * try {
 *   await doSomething()
 * } catch (err) {
 *   logger.error(err, {
 *     context: { method: 'doSomething' }
 *   })
 * }
 */

import { datadogRum } from '@datadog/browser-rum'

import { newId } from '@/lib/utils/id'

export type LogLevel = 'trace' | 'debug' | 'info' | 'warn' | 'error'

type LogContext = {
  component?: string
  method?: string
  description?: string
  id?: string
  [key: string]: any
}

type StandardLogOptions = {
  /** Additional context for logging */
  context?: LogContext
}

type ErrorLogOptions = {
  /** Additional context for logging */
  context?: LogContext
}

export type Logger = {
  source: string
  trace(message: string, options?: StandardLogOptions): void
  debug(message: string, options?: StandardLogOptions): void
  info(message: string, options?: StandardLogOptions): void
  warn(message: string, options?: StandardLogOptions): void
  error(error: Error | string, options?: ErrorLogOptions): void
}

const loggers = new Map<string, Logger>()

function createConsoleMethod(level: LogLevel, source: string): (message: string | Error) => void {
  const consoleMethod = level === 'trace' || level === 'debug' ? 'log' : level
  return Function.prototype.bind.call(console[consoleMethod], console, `[${level.toUpperCase().padEnd(5)}] ${source}`)
}

function sendToDatadog(source: string, actionType: string, data: unknown, context?: LogContext): void {
  try {
    if (data instanceof Error) {
      datadogRum.addError(data, {
        source
        // ...context
      })
    }
    /**
     * Leaving this here in case we want to add datadog tracking to other non-error events
     */
    // const actionName = [source, context?.method, context?.description, actionType].filter(Boolean).join(' - ')
    // datadogRum.addAction(actionName, {
    //   data,
    //   ...context
    // })
  } catch (err) {
    console.warn('Failed to send to Datadog:', err)
  }
}

function getLoggerKey(source: string): string {
  return source
}

/**
 * Gets a logger instance for the specified source.
 *
 * @param source - Identifier for the logging source (e.g., component name, service name)
 * @param options - Configuration options for the logger
 * @returns Logger instance with methods for different log levels
 */
export function getLogger(source: string): Logger {
  const key = getLoggerKey(source)

  if (loggers.has(key)) {
    return loggers.get(key)!
  }

  function log(level: LogLevel, message: string, options?: StandardLogOptions): void {
    const consoleLogger = createConsoleMethod(level, source)

    if (options?.context?.method) {
      consoleLogger(`${options.context.method}: ${message}`)
    } else {
      consoleLogger(message)
    }
  }

  const logger: Logger = {
    source,

    trace: (message: string, options?: StandardLogOptions): void => {
      log('trace', message, options)
    },

    debug: (message: string, options?: StandardLogOptions): void => {
      log('debug', message, options)
    },

    info: (message: string, options?: StandardLogOptions): void => {
      log('info', message, options)
    },

    warn: (message: string, options?: StandardLogOptions): void => {
      log('warn', message, options)
    },

    error: (error: Error | string, options?: ErrorLogOptions): void => {
      const id = newId('error')
      const consoleLogger = createConsoleMethod('error', source)
      const errorObject = typeof error === 'string' ? new Error(error) : error

      if (options?.context?.method) {
        consoleLogger(`${options.context.method}: ${errorObject.message}`)
      } else {
        consoleLogger(errorObject)
      }

      if (import.meta.env.VITE_DATADOG_RUM_ENABLED === 'true') {
        sendToDatadog(source, 'error', errorObject, { ...options?.context, id })
      }
    }
  }

  loggers.set(key, logger)
  return logger
}
