<script setup lang="ts">
import type { PropType } from 'vue'
import { ref } from 'vue'
import { DialogClose } from 'radix-vue'

import { cn } from '@/lib/utils'

import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle
} from '@/component/arqu-components/shadcn/ui/dialog'

const props = defineProps({
  title: {
    type: String,
    required: false,
    default: 'Confirm'
  },
  description: {
    type: String,
    required: false,
    default: 'Are you sure?'
  },
  okText: {
    type: String,
    required: false,
    default: 'Ok'
  },
  cancelText: {
    type: String,
    required: false,
    default: 'Cancel'
  },
  resolve: {
    type: Function as PropType<(value: boolean) => void>,
    required: false,
    default: () => {}
  },
  cardClass: {
    type: String,
    required: false,
    default: ''
  },
  descriptionClass: {
    type: String,
    required: false,
    default: ''
  },
  onOk: {
    type: Function as PropType<() => void | Promise<void>>,
    required: false,
    default: () => {}
  },
  onCancel: {
    type: Function as PropType<() => void | Promise<void>>,
    required: false,
    default: () => {}
  },
  icon: {
    type: String,
    required: false,
    default: ''
  },
  displayCancel: {
    type: Boolean,
    required: false,
    default: true
  }
})

const open = ref(true)
const loading = ref(false)

async function handleClose() {
  if (props.resolve) {
    props.resolve(false)
  }
  open.value = false
}

async function handleCancel() {
  await props?.onCancel?.()
  if (props.resolve) {
    props.resolve(false)
  }
  open.value = false
}

async function handleConfirm() {
  loading.value = true
  await props?.onOk?.()
  if (props.resolve) {
    props.resolve(true)
  }
  open.value = false
  loading.value = false
}
</script>

<template>
  <Dialog :open="open">
    <DialogContent :class="cn('w-fit min-w-[35vw] max-w-[45vw]', cardClass)">
      <template #close>
        <DialogClose
          class="absolute right-3 top-3 rounded-md p-0.5 transition-colors hover:bg-gray-100"
          datacy="dialog-close"
          @click="handleClose"
        >
          <rq-icon icon="lucide:x" />
          <span class="sr-only">Close</span>
        </DialogClose>
      </template>
      <DialogHeader>
        <DialogTitle>
          <slot name="title">
            {{ title }}
          </slot>
        </DialogTitle>
        <DialogDescription class="sr-only">Confirmation dialog</DialogDescription>
      </DialogHeader>
      <div :class="descriptionClass">
        <slot name="description">{{ description }}</slot>
      </div>
      <DialogFooter class="pt-4">
        <rq-btn v-if="displayCancel" id="cancel-btn" :disabled="loading" type="button" variant="outline" @click="handleCancel">
          {{ cancelText }}
        </rq-btn>
        <rq-btn id="success-btn" datacy="dialogCardActionsSubmitButton" type="button" variant="primary" @click="handleConfirm">
          {{ okText }}
        </rq-btn>
      </DialogFooter>
    </DialogContent>
  </Dialog>
</template>
