import { useAcl } from 'vue-simple-acl/src'

import type { DealModelWithMemo, DealStatus } from '@/capability/deal/types'
import type { DealModel } from '@/capability/deal/types/deal-model'
import { resourceModelFactory } from '@/capability/resource/ResourceModelFactory'
import { apiConfiguration } from '@/capability/system/Config'
import type {
  DealMinimalDto,
  DocumentDto,
  GetMinimalDealsByScoreResponse,
  HeadlineMetrics,
  ListDocumentsResponse,
  MemoDto,
  UpdateDealCalendarEventRequest,
  UpdateDealFromDocumentAnnotationRequest,
  UserDto
} from 'typescript-core-api-client'
import { DealApi } from 'typescript-core-api-client'
import type { CreateDealCalendarEventRequest } from 'typescript-core-api-client/dist/api'
import type { ContractorCheckResultDto, GoogleCalendarEventDto } from 'typescript-core-api-client/dist/api'
import type { GetDealStatisticsResponse } from 'typescript-core-api-client/dist/api'
import type { Configuration as CoreApiConfiguration } from 'typescript-core-api-client/dist/configuration'

export type SortByType = 'Client' | 'CreatedAt' | 'ModifiedAt' | 'Name' | 'Owner'
export type OrderByType = 'Asc' | 'Desc'

export type ListDealsReturnType = {
  totalCount: number
  deals: DealModelWithMemo[]
}

export type ListMinimalDealsReturnType = {
  totalCount: number
  deals: DealMinimalDto[]
}

type ListDealsPayloadType = {
  search?: string
  status?: DealStatus[]
  sortedBy?: SortByType
  sortOrder?: OrderByType
  pageNumber?: number
  pageSize?: number
  activity?: boolean
  activityResultSize?: number
}

type ListMinimalDealsPayloadType = ListDealsPayloadType & {
  memo?: boolean
}

export type UploadDocumentPayloadType = {
  dealId: string
  files: File[]
  documentName?: string
  documentType?: string
  policyId?: string
  policyName?: string
  validateCheckSum?: boolean
}

export type UploadFilesPayloadType = {
  dealId: string
  files: File[]
  paths: string[]
}

type GetDocumentsPayloadType = {
  dealId: string
}

type GetDealPrivilegesPayloadType = {
  dealId: string
}

type UpdateDealPayloadType = {
  deal: DealModel
}

type GetDealPrivilegeUsersPayloadType = {
  dealId: string
}

type SendAnnotationToDealPayloadType = {
  request: UpdateDealFromDocumentAnnotationRequest
}

type GetHeadlineMetricsPayloadType = {
  dealId: string
}

type GetDealEventsPayloadType = {
  dealId: string
}

export type CreateDealEventPayloadType = CreateDealCalendarEventRequest & {
  dealId: string
}

export type UpdateDealEventPayloadType = UpdateDealCalendarEventRequest & {
  dealId: string
  eventId: string
}

type GetContractorCheckResultsPayloadType = {
  dealId: string
}

type GetDealStatisticsPayloadType = {
  filter?: string | undefined
  getAll?: boolean
  interval?: string
  role?: 'BOTH' | 'CARRIER' | 'RETAILER'
  size?: number
}

type GetDealScoresPayloadType = {
  lastId?: string
  size?: number
}

export interface Index {
  listDeals: (payload: ListDealsPayloadType) => Promise<ListDealsReturnType>
  listMinimalDeals: (payload: ListMinimalDealsPayloadType) => Promise<ListMinimalDealsReturnType>
  uploadDocument: (payload: UploadDocumentPayloadType) => Promise<DocumentDto[]>
  uploadFiles: (payload: UploadFilesPayloadType) => Promise<string>
  uploadDealImage: (payload: UploadFilesPayloadType) => Promise<string>
  uploadDealRiskConsideration: (payload: UploadFilesPayloadType) => Promise<string>
  getDocuments: (payload: GetDocumentsPayloadType) => Promise<ListDocumentsResponse>
  getDealPrivilegeUsers: (payload: GetDealPrivilegeUsersPayloadType) => Promise<UserDto[]>
  sendAnnotationToDeal: (payload: SendAnnotationToDealPayloadType) => Promise<void>
  getHeadlineMetrics: (payload: GetHeadlineMetricsPayloadType) => Promise<HeadlineMetrics>
  getDealEvents: (payload: GetDealEventsPayloadType) => Promise<GoogleCalendarEventDto[]>
  createDealEvent: (payload: CreateDealEventPayloadType) => Promise<GoogleCalendarEventDto[]>
  updateDealEvent: (payload: UpdateDealEventPayloadType) => Promise<GoogleCalendarEventDto[]>
  getContractorCheckResults: (payload: GetContractorCheckResultsPayloadType) => Promise<ContractorCheckResultDto[]>
  getDealStatistics: (payload: GetDealStatisticsPayloadType) => Promise<GetDealStatisticsResponse>
  getDealScores: (payload: GetDealScoresPayloadType) => Promise<GetMinimalDealsByScoreResponse>
}

export const dealService = {
  listDeals: async function (payload: ListDealsPayloadType): Promise<ListDealsReturnType> {
    const { search, status, sortedBy, sortOrder, pageNumber, pageSize } = payload
    const acl = useAcl()
    const config: CoreApiConfiguration = await apiConfiguration()
    const dealApi = new DealApi(config)
    const response = await dealApi.list(true, pageNumber, pageSize, search, sortedBy, sortOrder, status)
    const memos: Map<string, MemoDto> = new Map()
    if (response.data.memos) {
      for (const memo of response.data.memos) {
        if (memo && memo.dealId) {
          memos.set(memo.dealId, memo)
        }
      }
    }

    return {
      totalCount: response.data.totalCount ?? 0,
      deals: (response.data.deals ?? []).map((deal) => {
        const dealDto = { ...deal, memo: memos.get(deal.id!) }
        return resourceModelFactory.buildDeal({ dealDto }, false)
      })
    }
  },
  listMinimalDeals: async function (payload: ListMinimalDealsPayloadType): Promise<ListMinimalDealsReturnType> {
    const { activity, activityResultSize, memo, search, status, sortedBy, sortOrder, pageNumber, pageSize } = payload
    const acl = useAcl()
    const config: CoreApiConfiguration = await apiConfiguration()
    const dealApi = new DealApi(config)
    const response = await dealApi.listMinimal(
      activity,
      activityResultSize,
      memo,
      pageNumber,
      pageSize,
      search,
      sortedBy,
      sortOrder,
      status
    )

    const memos: Map<string, MemoDto> = new Map()
    if (response.data.memos) {
      for (const memo of response.data.memos) {
        if (memo && memo.dealId) {
          memos.set(memo.dealId, memo)
        }
      }
    }

    return {
      totalCount: response.data.totalCount ?? 0,
      deals: (response.data.deals ?? []).map((deal) => {
        const dealDto = { ...deal, memo: memos.get(deal.id!) }
        return resourceModelFactory.buildDeal({ dealDto }, false)
      })
    }
  },
  uploadDocument: async function (payload: UploadDocumentPayloadType): Promise<DocumentDto[]> {
    const { dealId, files, documentName, documentType, policyId, policyName, validateCheckSum } = payload
    const config: CoreApiConfiguration = await apiConfiguration()
    const dealApi = new DealApi(config)

    const formData = new FormData()
    for (const file of files) {
      formData.append('file', file)
    }
    // todo(jeff): fix the way this is sent. it seems superfluous to have to send the files as an argument as well as create
    //  and send the formData as an axios "data" option

    // @ts-ignore - the generated upload api is slightly different
    const response = await dealApi.upload(dealId, formData, documentName, documentType, policyId, policyName, validateCheckSum, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })

    if (!response.data.documents) return [response.data.document!]
    return response.data.documents
  },
  uploadFiles: async function (payload: UploadFilesPayloadType): Promise<string> {
    const { dealId, files, paths } = payload
    const config: CoreApiConfiguration = await apiConfiguration()
    const dealApi = new DealApi(config)

    // console.log('uploadFiles --> ', files)
    const fileData = new FormData()
    for (const file of files) {
      fileData.append('files', file)
    }
    const pathData = new FormData()
    for (const path of paths) {
      pathData.append('filePaths', path)
    }
    // const payload: UploadFilesRequest = {
    //  files: formData,
    //  filePaths: paths
    // } as UploadFilesRequest
    // @ts-ignore - the generated upload api is slightly different
    const response = await dealApi.uploadFiles(dealId, paths, fileData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
    // this is the UploadFilesResponse
    return response.data.riskNarrativeURL ?? ''
  },
  uploadDealImage: async function (payload: UploadFilesPayloadType): Promise<string> {
    const { dealId, files, paths } = payload
    const config: CoreApiConfiguration = await apiConfiguration()
    const dealApi = new DealApi(config)

    // console.log('uploadFiles --> ', files)
    const fileData = new FormData()
    for (const file of files) {
      fileData.append('files', file)
    }
    const pathData = new FormData()
    for (const path of paths) {
      pathData.append('filePaths', path)
    }
    // @ts-ignore - the generated upload api is slightly different
    const response = await dealApi.uploadDealImage(dealId, paths, fileData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
    // this is the UploadFilesResponse
    return response.data.dealImageLocation ?? ''
  },
  uploadDealRiskConsideration: async function (payload: UploadFilesPayloadType): Promise<string> {
    const { dealId, files, paths } = payload
    const config: CoreApiConfiguration = await apiConfiguration()
    const dealApi = new DealApi(config)

    // console.log('uploadFiles --> ', files)
    const fileData = new FormData()
    for (const file of files) {
      fileData.append('files', file)
    }
    const pathData = new FormData()
    for (const path of paths) {
      pathData.append('filePaths', path)
    }
    // @ts-ignore - the generated upload api is slightly different
    const response = await dealApi.uploadDealRiskConsideration(dealId, paths, fileData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
    // this is the UploadFilesResponse
    return response.data.dealRiskConsiderationLocation ?? ''
  },
  getDocuments: async function (payload: GetDocumentsPayloadType): Promise<ListDocumentsResponse> {
    const { dealId } = payload
    const config: CoreApiConfiguration = await apiConfiguration()
    const dealApi = new DealApi(config)

    const response = await dealApi.listDocuments(dealId)
    return response.data
  },
  getDealPrivilegeUsers: async function (payload: GetDealPrivilegeUsersPayloadType): Promise<UserDto[]> {
    const { dealId } = payload
    const config: CoreApiConfiguration = await apiConfiguration()
    const dealApi = new DealApi(config)

    const response = await dealApi.listPrivilegeUsers(dealId)
    return response.data.users ?? []
  },
  sendAnnotationToDeal: async function (payload: SendAnnotationToDealPayloadType): Promise<void> {
    const { request } = payload
    const config: CoreApiConfiguration = await apiConfiguration()
    const dealApi = new DealApi(config)

    await dealApi.updateDealFromDocumentAnnotation(request)
    return Promise.resolve()
  },
  getHeadlineMetrics: async function (payload: GetHeadlineMetricsPayloadType): Promise<HeadlineMetrics> {
    const { dealId } = payload
    const config: CoreApiConfiguration = await apiConfiguration()
    const dealApi = new DealApi(config)

    const response = await dealApi.getHeadlineMetrics(dealId)
    return response.data.headlineMetrics ?? {}
  },
  getDealEvents: async function (payload: GetDealEventsPayloadType): Promise<GoogleCalendarEventDto[]> {
    const { dealId } = payload
    const config: CoreApiConfiguration = await apiConfiguration()
    const dealApi = new DealApi(config)

    const response = await dealApi.getEvents(dealId)
    return response.data?.events ?? []
  },
  createDealEvent: async function (payload: CreateDealEventPayloadType): Promise<GoogleCalendarEventDto[]> {
    const { dealId, ...rest } = payload
    const config: CoreApiConfiguration = await apiConfiguration()
    const dealApi = new DealApi(config)

    const response = await dealApi.createEvent(dealId, rest as CreateDealCalendarEventRequest)
    return response.data?.events ?? []
  },
  updateDealEvent: async function (payload: UpdateDealEventPayloadType): Promise<GoogleCalendarEventDto[]> {
    const { dealId, eventId, ...rest } = payload
    const config: CoreApiConfiguration = await apiConfiguration()
    const dealApi = new DealApi(config)

    const response = await dealApi.updateEvent(dealId, eventId, { ...rest, eventId } as UpdateDealCalendarEventRequest)
    return response.data?.events ?? []
  },
  getContractorCheckResults: async function (payload: GetContractorCheckResultsPayloadType): Promise<ContractorCheckResultDto[]> {
    const { dealId } = payload
    const config: CoreApiConfiguration = await apiConfiguration()
    const dealApi = new DealApi(config)

    const response = await dealApi.getContractorCheckResults(dealId)
    return response.data.results ?? []
  },
  getDealStatistics: async function ({
    filter,
    getAll,
    interval,
    role,
    size
  }: GetDealStatisticsPayloadType): Promise<GetDealStatisticsResponse> {
    const config: CoreApiConfiguration = await apiConfiguration()
    const dealApi = new DealApi(config)

    const response = await dealApi.listByType(filter, getAll, interval, role, size)
    return response.data
  },
  getDealScores: async function ({ lastId, size }: GetDealScoresPayloadType) {
    const config: CoreApiConfiguration = await apiConfiguration()
    const dealApi = new DealApi(config)

    const response = await dealApi.listByScore(lastId, size)
    return response.data
  }
} as Index
