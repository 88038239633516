<script setup lang="ts">
import { type PropType, ref } from 'vue'

import { useNotificationStore } from '@/stores/notification'

import { lineItemService } from '@/capability/line-item/LineItemService'
import type { TowerModel } from '@/capability/tower/TowerModel'
import type { LineItemTemplateDto } from 'typescript-core-api-client'

import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger
} from '@/component/arqu-components/shadcn/ui/dialog'
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/component/arqu-components/shadcn/ui/tooltip'

const props = defineProps({
  lineItemTemplates: {
    type: Array as PropType<LineItemTemplateDto[]>,
    required: false,
    default: () => []
  },
  tower: {
    type: Object as PropType<TowerModel>,
    required: true
  },
  name: {
    type: String,
    required: true
  },
  lineItemTemplateId: {
    type: String,
    required: true
  }
})

const emit = defineEmits<{
  (e: 'submit'): void
}>()

const notificationStore = useNotificationStore()

const open = ref<boolean>(false)
const loading = ref<boolean>(false)

async function handleSubmit() {
  try {
    loading.value = true
    await lineItemService.deleteTowerLineItemTemplate({ lineItemTemplateId: props.lineItemTemplateId })
    notificationStore.publishSuccessMessage('Template deleted')
    emit('submit')
    open.value = false
  } catch (err) {
    notificationStore.publishOneOrMoreErrUnhandled(err as unknown as Error)
  } finally {
    loading.value = false
  }
}
</script>

<template>
  <Dialog v-model:open="open">
    <TooltipProvider>
      <Tooltip>
        <TooltipTrigger as-child>
          <DialogTrigger as-child>
            <rq-btn
              :datacy="`delete-line-item-template-${name}`"
              icon="square"
              size="lg"
              variant="ghost"
              vitest="delete-line-item-template"
            >
              <rq-icon class="text-red-500" icon="lucide:trash" />
            </rq-btn>
          </DialogTrigger>
        </TooltipTrigger>
        <TooltipContent>Delete</TooltipContent>
      </Tooltip>
    </TooltipProvider>
    <DialogContent class="min-w-[30vw]" @escape-key-down.prevent="" @interact-outside.prevent="" @pointer-down-outside.prevent="">
      <DialogHeader>
        <DialogTitle>Delete Template</DialogTitle>
        <DialogDescription>Do you want to delete the template "{{ name }}"?</DialogDescription>
      </DialogHeader>
      <form @submit.prevent="handleSubmit">
        <DialogFooter>
          <rq-btn id="cancel-btn" :disabled="loading" type="button" variant="outline" @click="open = false">Cancel</rq-btn>
          <rq-btn id="success-btn" datacy="dialogCardActionsSubmitButton" :loading="loading" type="submit" variant="primary">Delete</rq-btn>
        </DialogFooter>
      </form>
    </DialogContent>
  </Dialog>
</template>

<style scoped lang="scss"></style>
