<script lang="ts">
import type { ProgramModel } from '@/capability/program/ProgramModel'

export type Props = {
  program: ProgramModel
  datacy?: string
}
</script>

<script setup lang="ts">
import { ref } from 'vue'

import { DropdownMenu, DropdownMenuContent, DropdownMenuTrigger } from '@/component/arqu-components/shadcn/ui/dropdown-menu'
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/component/arqu-components/shadcn/ui/tooltip'
import DealProgramOptionsCopy from '@/component/program/program-options/DealProgramOptionsCopy.vue'
import DealProgramOptionsDelete from '@/component/program/program-options/DealProgramOptionsDelete.vue'
import DealProgramOptionsEdit from '@/component/program/program-options/DealProgramOptionsEdit.vue'
import DealProgramOptionsRename from '@/component/program/program-options/DealProgramOptionsRename.vue'
import DealProgramOptionsSaveSpec from '@/component/program/program-options/DealProgramOptionsSaveSpec.vue'

withDefaults(defineProps<Props>(), { datacy: '' })

const open = ref<boolean>(false)
const keepOpen = ref<boolean>(false)

function close() {
  keepOpen.value = false
  handleUpdateOpen(false)
}

function handleUpdateOpen(e: boolean) {
  if (e || !keepOpen.value) {
    open.value = e
  }
}

function handleKeepOpen(e: boolean) {
  keepOpen.value = e
}
</script>

<template>
  <DropdownMenu :open="open" @update:open="handleUpdateOpen">
    <TooltipProvider>
      <Tooltip>
        <TooltipTrigger as-child>
          <DropdownMenuTrigger as-child>
            <rq-btn :datacy icon="square" size="lg" variant="ghost">
              <rq-icon icon="lucide:menu" />
            </rq-btn>
          </DropdownMenuTrigger>
          <DropdownMenuContent class="py-0">
            <DealProgramOptionsSaveSpec :program @close="close" @keep-open="handleKeepOpen" />
            <DealProgramOptionsCopy :program @close="close" @keep-open="handleKeepOpen" />
            <DealProgramOptionsRename :program @close="close" @keep-open="handleKeepOpen" />
            <DealProgramOptionsEdit :program @close="close" @keep-open="handleKeepOpen" />
            <DealProgramOptionsDelete :program @close="close" @keep-open="handleKeepOpen" />
          </DropdownMenuContent>
        </TooltipTrigger>
        <TooltipContent>Program Options</TooltipContent>
      </Tooltip>
    </TooltipProvider>
  </DropdownMenu>
</template>

<style scoped lang="scss"></style>
