export default {
  path: '/open',
  component: () => import('@/component/open/OpenViewWrapper.vue'),
  children: [
    {
      path: 'risk-narrative',
      name: 'RiskNarrative-Open',
      component: () => import('@/views/open/OpenRiskNarrative.vue'),
      meta: {
        requiresAuth: false,
        title: 'Risk Narrative',
        pageViewDetails: {
          pageId: 'open-risk-narrative-view',
          resourceType: 'open',
          resourceCrudl: 'view'
        }
      }
    },
    {
      path: 'documents',
      name: 'Documents-Open',
      component: () => import('@/views/open/OpenDocumentRepo.vue'),
      meta: {
        requiresAuth: false,
        pageViewDetails: {
          pageId: 'open-documents-view',
          resourceType: 'open',
          resourceCrudl: 'view'
        },
        title: 'Open Document View'
      }
    },
    {
      path: 'documents/preview/:docId',
      name: 'DocumentPreview-Open',
      component: () => import('@/views/open/OpenDocumentPreview.vue'),
      meta: {
        requiresAuth: false,
        pageViewDetails: {
          pageId: 'open-document-preview',
          resourceType: 'document',
          resourceCrudl: 'preview'
        },
        title: 'Document Preview'
      }
    },
    {
      path: 'risk-attributes-statement-of-values',
      name: 'RiskStatementOfValues-Open',
      alias: 'arqu%20SOV',
      component: () => import('@/views/open/OpenStatementOfValueSpreadsheet.vue'),
      meta: {
        requiresAuth: false,
        pageViewDetails: {
          pageId: 'open-risk-attribute-sov',
          resourceType: 'open',
          resourceCrudl: 'view'
        },
        title: 'Statement of Values'
      }
    }
  ]
}
