import { computed, ref } from 'vue'
import { type RouteLocationNormalized, useRoute } from 'vue-router'
import { useLocalStorage } from '@vueuse/core'
import { defineStore } from 'pinia'

import { getLogger } from '@/composables/util/log'
import { useNotificationStore } from '@/stores/notification'

import type { FeatureModel } from '@/capability/feature/featureModel'
import type { UserModel } from '@/capability/user/model'
import { userService } from '@/capability/user/UserService'

type DefaultRsLandingType = 'dashboard' | 'deal-hub'
export type AllProgramSectionsVisibilityType = 'show' | 'hide'

export type FetchPrivilegesPayloadType = {
  dealId: string
}

export const useAuthStore = defineStore('auth', () => {
  const route = useRoute()
  const logger = getLogger('useAuthStore')
  const notificationStore = useNotificationStore()

  const accessToken = ref<string>('')
  const legacyRefreshToken = window?.localStorage?.getItem('refreshToken') ?? ''
  const refreshToken = ref<string>(
    useLocalStorage(`rq-refresh-token-${import.meta.env.VITE_ARQU_ENVIRONMENT}`, legacyRefreshToken) as unknown as string
  )
  const user = ref<UserModel | undefined>(undefined)
  const privileges = ref<string[]>([])
  const features = ref<FeatureModel[]>([])
  const redirectUrl = ref<string>(useLocalStorage('redirectUrl', '') as unknown as string)
  const viewedAsUser = ref<UserModel | undefined>(undefined)
  const viewedAsPrivileges = ref<string[] | undefined>(undefined)
  const allProgramSectionsVisibility = ref<AllProgramSectionsVisibilityType>(
    useLocalStorage(
      `rq-all-program-sections-visibility-${import.meta.env.VITE_ARQU_ENVIRONMENT}`,
      'hide'
    ) as unknown as AllProgramSectionsVisibilityType
  )
  const _retailerView = ref<string>(useLocalStorage('retailerView', 'true') as unknown as string)
  const seenTours = ref<string[]>(useLocalStorage<string[]>('seenTours', []) as unknown as string[])
  const defaultRsLanding = ref<DefaultRsLandingType>(
    useLocalStorage<DefaultRsLandingType>(
      `rq-default-landing-${import.meta.env.VITE_ARQU_ENVIRONMENT}`,
      'deal-hub'
    ) as unknown as DefaultRsLandingType
  )

  const getUser = computed(() => {
    if (viewedAsUser.value) {
      return viewedAsUser.value
    } else {
      return user.value
    }
  })
  const getPrivileges = computed(() => {
    if (viewedAsPrivileges.value) {
      return viewedAsPrivileges.value
    } else {
      return privileges.value
    }
  })
  const userOrganizationId = computed(() => {
    if (viewedAsUser.value) {
      return viewedAsUser.value!.organizationId
    } else {
      return user.value?.organizationId
    }
  })
  const accordionNavigation = computed((): boolean => features.value.find((f) => f.name === 'line-item-accordion')?.enabled ?? false)
  const passwordlessAuthFeature = computed((): boolean => features.value.find((f) => f.name == 'passwordless-auth')?.enabled ?? false)
  const chatgptRetrievalFeature = computed((): boolean => features.value.find((f) => f.name == 'chatgpt-retrieval')?.enabled ?? false)
  const swimLaneChoiceEnabled = computed((): boolean => features.value.find((f) => f.name === 'toggle-swim-lanes-option')?.enabled ?? false)
  const downloadFileAsBase64 = computed((): boolean => features.value.find((f) => f.name === 'download-file-as-base64')?.enabled ?? false)
  const newDocRepoPreferred = ref<boolean>(
    useLocalStorage(`rq-doc-repo-preference-${import.meta.env.VITE_ARQU_ENVIRONMENT}-rc`, true) as unknown as boolean
  )

  const newDealKickoffPreferred = ref<boolean>(
    useLocalStorage(`rq-deal-kickoff-preference-${import.meta.env.VITE_ARQU_ENVIRONMENT}-rc`, true) as unknown as boolean
  )

  function getRedirectUrl(to: RouteLocationNormalized | null = null): string {
    const _redirectUrl = to?.fullPath || (route?.query?.redirectUrl as string) || redirectUrl.value || '/'
    if (decodeURIComponent(_redirectUrl).startsWith('/onboarding') || decodeURIComponent(_redirectUrl).startsWith('/verifyLogin')) {
      return '/'
    }
    return decodeURIComponent(_redirectUrl)
  }

  async function fetchPrivileges({ dealId }: FetchPrivilegesPayloadType) {
    if (!user.value?.id) {
      logger.warn('User is not logged in')
      return
    }
    userService
      .listPrivileges({ userId: user.value.id, dealId })
      .then(function (res) {
        privileges.value = res
      })
      .catch(function (err) {
        notificationStore.publishOneOrMoreErrUnhandled(err as unknown as Error)
      })
  }

  return {
    accessToken,
    refreshToken,
    user,
    privileges,
    features,
    redirectUrl,
    viewedAsUser,
    viewedAsPrivileges,
    _retailerView,
    seenTours,
    getUser,
    getPrivileges,
    userOrganizationId,
    accordionNavigation,
    allProgramSectionsVisibility,
    passwordlessAuthFeature,
    chatgptRetrievalFeature,
    swimLaneChoiceEnabled,
    downloadFileAsBase64,
    defaultRsLanding,
    newDocRepoPreferred,
    newDealKickoffPreferred,
    getRedirectUrl,
    fetchPrivileges
  }
})
