<script lang="ts">
import type { ProgramModel } from '@/capability/program/ProgramModel'

export type Props = {
  program: ProgramModel
  displayInfoTooltip?: boolean
  current?: boolean
  titleClass?: string
}
</script>

<script setup lang="ts">
import { computed } from 'vue'

import { cn } from '@/lib/utils'
import { useProgramStore } from '@/stores/program'

import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/component/arqu-components/shadcn/ui/tooltip'

const props = withDefaults(defineProps<Props>(), {
  displayInfoTooltip: false,
  current: false,
  titleClass: ''
})

const programStore = useProgramStore()

const dotClass = computed(() => {
  if (props.program!.status === 'Active') {
    return 'bg-green-500'
  }
  if (props.program!.visible) {
    return 'bg-yellow-500'
  }
  return 'bg-red-500'
})

const tooltipText = computed(() => {
  if (props.program!.status === 'Active') {
    return 'Program is active'
  }
  if (!props.program!.visible) {
    return 'Program is inactive and hidden'
  }
  return 'Program is inactive but visible'
})

const productTitle = computed(() => programStore.getProduct(props.program!)?.displayName)

const infoTooltipDisabled = computed(() => props.program?.title === productTitle.value || !props.displayInfoTooltip)
</script>

<template>
  <div :class="cn('inline-flex items-center space-x-1', ($attrs.class as string) ?? '')">
    <TooltipProvider :disabled="infoTooltipDisabled">
      <Tooltip>
        <TooltipTrigger as-child>
          <div :class="cn(current ? 'text-primary-600' : '', titleClass)">
            {{ program!.title! }}
          </div>
        </TooltipTrigger>
        <TooltipContent>
          <p>Program Name: {{ program.title }}</p>
          <p>Product: {{ productTitle }}</p>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
    <TooltipProvider :delay-duration="0">
      <Tooltip>
        <TooltipTrigger as-child>
          <div :class="cn('my-auto h-3 w-3 rounded-full', dotClass)"></div>
        </TooltipTrigger>
        <TooltipContent>
          {{ tooltipText }}
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  </div>
</template>

<style scoped lang="scss"></style>
