<script setup lang="ts">
import type { PropType } from 'vue'
import { ref } from 'vue'
import { watch } from 'vue'
import { z } from 'zod'

import { useNewZod } from '@/composables/zod'
import type { ErrorType } from '@/lib/types'

import type { OrganizationModel } from '@/capability/organization/model'
import { documentTypes, extractionTypes, lineOfBusinesses } from '@/capability/prompt/constants'
import type { PromptModel } from '@/capability/prompt/PromptModel'
import { DocumentChatRequestAgentTypeEnum } from 'typescript-core-api-client'

import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger
} from '@/component/arqu-components/shadcn/ui/dialog'

const props = defineProps({
  prompt: {
    type: Object as PropType<PromptModel>,
    required: true
  },
  mode: {
    type: String as PropType<'create' | 'update'>,
    required: true
  },
  organizations: {
    type: Array as PropType<OrganizationModel[]>,
    required: false,
    default: () => []
  }
})

const emit = defineEmits<{
  (e: 'submit', payload: PromptModel): void
  (e: 'cancel'): void
}>()

const dialog = ref<boolean>(false)

const stateObject: { [K in keyof PromptModel]: unknown } = Object.assign({}, props.prompt)

const schemaObject = {
  name: z.string().min(1, 'Name is required'),
  documentType: z.string().min(1, 'Document Type is required'),
  extractionType: z.string().min(1, 'Extraction Type is required'),
  lineOfBusiness: z.string().min(1, 'Line of Business is required'),
  organizationId: z.string().min(1, 'Organization is required'),
  order: z.number().min(0),
  prompt: z.string().min(1, 'Prompt is required')
}

const state = ref<PromptModel>(Object.assign({}, props.prompt))

const { errors, resetState, resetErrors, validate } = useNewZod<typeof stateObject, ErrorType<typeof stateObject>>({
  state,
  stateObject,
  schemaObject
})

watch(
  dialog,
  (value) => {
    if (value) {
      resetState()
      resetErrors()
    }
  },
  { immediate: true }
)

function handleSubmit() {
  const valid = validate()
  if (valid) {
    emit('submit', state.value as PromptModel)
    dialog.value = false
  }
}
</script>

<template>
  <Dialog v-model:open="dialog">
    <DialogTrigger as-child>
      <rq-btn icon="square" size="lg" variant="ghost-primary">
        <rq-icon :icon="mode === 'create' ? 'lucide:circle-plus' : 'lucide:edit'" />
      </rq-btn>
    </DialogTrigger>
    <DialogContent
      class="w-[95vw] max-w-[100vw] sm:w-[80vw] md:w-[70vw] lg:w-[50vw] xl:w-[30vw]"
      @escape-key-down.prevent=""
      @interact-outside.prevent=""
      @pointer-down-outside.prevent=""
    >
      <DialogHeader>
        <DialogTitle>{{ mode === 'create' ? 'Create new prompt' : `Update prompt - ${state.name}` }}</DialogTitle>
        <DialogDescription>
          {{ mode === 'create' ? 'Create a new' : 'Update the existing' }} prompt for use in extractions
        </DialogDescription>
      </DialogHeader>
      <form @submit.prevent="handleSubmit">
        <rq-text-field v-if="mode === 'update'" v-model="state.id" id="id" :disabled="true" label="ID" />
        <rq-text-field v-model="state.name" id="name" :errors="errors.name" label="Name" required />
        <rq-text-field v-model="state.description" id="description" label="Description" />
        <rq-listbox-single
          v-model="state.documentType"
          id="document-type"
          :errors="errors.documentType"
          :items="documentTypes"
          label="Document Type"
          text-field="label"
          value-field="name"
        />
        <rq-listbox-single
          v-model="state.extractionType"
          id="extraction-type"
          :errors="errors.extractionType"
          :items="extractionTypes"
          label="Extraction Type"
          text-field="label"
          value-field="name"
        />
        <rq-listbox-single
          v-model="state.documentAgentType"
          id="document-query-type"
          :items="[
            DocumentChatRequestAgentTypeEnum.Qa,
            DocumentChatRequestAgentTypeEnum.QuoteExtraction,
            DocumentChatRequestAgentTypeEnum.LossRunExtraction
          ]"
          label="Agent Type"
        />
        <rq-listbox-single
          v-model="state.lineOfBusiness"
          id="line-of-business"
          :errors="errors.lineOfBusiness"
          :items="lineOfBusinesses"
          label="Line of Business"
          text-field="label"
          value-field="name"
        />
        <rq-combobox-single
          v-if="mode === 'create'"
          v-model="state.organizationId"
          id="organization-id"
          :errors="errors.organizationId"
          :items="organizations"
          label="Organization"
          text-field="name"
          :truncate-length="24"
          value-field="id"
          wrapper-class="col-span-1"
        />
        <rq-text-field
          v-model="state.order"
          id="order"
          :errors="errors.order"
          hint="Order in which the prompt will be extracted"
          label="Order"
          required
          type="number"
        />
        <rq-textarea v-model="state.prompt" id="prompt" :errors="errors.prompt" label="Prompt" />
        <DialogFooter class="space-y-2 pt-2 sm:space-x-2 sm:space-y-0">
          <div class="flex space-x-2">
            <rq-btn id="cancel-btn" type="button" variant="outline" @click="dialog = false">Cancel</rq-btn>
            <rq-btn id="success-btn" datacy="dialogCardActionsSubmitButton" type="submit" variant="primary">
              {{ mode === 'create' ? 'Create' : 'Update' }}
            </rq-btn>
          </div>
        </DialogFooter>
      </form>
    </DialogContent>
  </Dialog>
</template>

<style scoped lang="scss"></style>
