<script setup lang="ts">
import type { PropType } from 'vue'
import { ref } from 'vue'
import cloneDeep from 'lodash.clonedeep'

import { useNotificationStore } from '@/stores/notification'

import type { LineItemTargetModel } from '@/capability/line-item/model'
import type { TowerModel } from '@/capability/tower/TowerModel'
import type { LineItemTemplateDto } from 'typescript-core-api-client'

import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger
} from '@/component/arqu-components/shadcn/ui/dialog'
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/component/arqu-components/shadcn/ui/tooltip'

const props = defineProps({
  lineItemTemplates: {
    type: Array as PropType<LineItemTemplateDto[]>,
    required: false,
    default: () => []
  },
  tower: {
    type: Object as PropType<TowerModel>,
    required: true
  },
  name: {
    type: String,
    required: true
  },
  lineItemTemplateId: {
    type: String,
    required: true
  }
})

const emit = defineEmits<{
  (e: 'submit', payload: TowerModel): void
}>()

const notificationStore = useNotificationStore()

const loading = ref<boolean>(false)
const open = ref<boolean>(false)

async function handleSubmit() {
  try {
    loading.value = true
    // get the template we are going to apply
    const template = props.lineItemTemplates.find((template) => template.id === props.lineItemTemplateId)

    // copy the tower where we are going to apply template
    const copyOfTower = cloneDeep(props.tower as TowerModel)
    const _lineItemTargets = copyOfTower.lineItemTargets ?? []
    template?.lineItemTargets?.forEach((lineItemTarget: LineItemTargetModel) => {
      // check to see if the Tower already has this lineItemTarget
      if (_lineItemTargets.find((lineItem) => lineItem.lineItemId === lineItemTarget.lineItemId) == undefined) {
        _lineItemTargets?.push(lineItemTarget)
      }
    })
    copyOfTower.lineItemTargets = _lineItemTargets
    open.value = false
    emit('submit', copyOfTower)
  } catch (err) {
    notificationStore.publishOneOrMoreErrUnhandled(err as unknown as Error)
  } finally {
    loading.value = false
  }
}
</script>

<template>
  <Dialog v-model:open="open">
    <TooltipProvider>
      <Tooltip>
        <TooltipTrigger as-child>
          <DialogTrigger as-child>
            <rq-btn :datacy="`apply-line-item-template-${name}`" icon="square" size="lg" variant="ghost" vitest="apply-line-item-template">
              <rq-icon class="text-green-500" icon="lucide:arrow-big-right" />
            </rq-btn>
          </DialogTrigger>
        </TooltipTrigger>
        <TooltipContent>Apply</TooltipContent>
      </Tooltip>
    </TooltipProvider>
    <DialogContent class="min-w-[30vw]" @escape-key-down.prevent="" @interact-outside.prevent="" @pointer-down-outside.prevent="">
      <DialogHeader>
        <DialogTitle>Apply Template</DialogTitle>
        <DialogDescription>Do you want to apply the template "{{ name }}"?</DialogDescription>
      </DialogHeader>
      <form @submit.prevent="handleSubmit">
        <DialogFooter>
          <rq-btn id="cancel-btn" :disabled="loading" type="button" variant="outline" @click="open = false">Cancel</rq-btn>
          <rq-btn id="success-btn" datacy="dialogCardActionsSubmitButton" :loading="loading" type="submit" variant="primary">Apply</rq-btn>
        </DialogFooter>
      </form>
    </DialogContent>
  </Dialog>
</template>

<style scoped lang="scss"></style>
