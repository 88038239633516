<script setup lang="ts">
import type { PropType } from 'vue'
import { computed, nextTick, ref } from 'vue'
import type { AbilityArgs } from 'vue-simple-acl'
import { useAcl } from 'vue-simple-acl/src'
import cloneDeep from 'lodash.clonedeep'

import { cn } from '@/lib/utils'
import colors from '@/lib/utils/colors'
import { useAuthStore } from '@/stores/auth'
import { useProgramStore } from '@/stores/program'
import { useTowerStore } from '@/stores/towers'

import type { ExposureModel } from '@/capability/exposure/ExposureModel'
import type { ProgramModel } from '@/capability/program/ProgramModel'
import { programService } from '@/capability/program/ProgramService'
import { handleSave, type HandleSavePayloadType } from '@/capability/program/utils'
import type { TowerModel } from '@/capability/tower/TowerModel'
import { getAllTowerLayers } from '@/capability/tower/util'

import ProgramIndicationAssignSelectLayer from '@/component/program/ProgramIndicationAssignSelectLayer.vue'
import TowerModifyStructure from '@/component/tower/TowerModifyStructure.vue'
import TowerSchematicComponent from '@/component/tower/TowerSchematicComponent.vue'
import TowerTermsAndConditionsNavigation from '@/component/tower/TowerTAndCNav.vue'

// Defines
const props = defineProps({
  programModel: {
    type: Object as PropType<ProgramModel>,
    required: true
  },
  showAddTowerButton: {
    type: Boolean,
    required: false,
    default: true
  },
  showAssignMarketButton: {
    type: Boolean,
    required: false,
    default: false
  },
  exposuresModel: {
    type: Array as PropType<ExposureModel[]>,
    required: true
  }
})

const emit = defineEmits<{
  (e: 'update:exposuresModel', payload: ExposureModel[]): void
}>()

const towerStore = useTowerStore()
const programStore = useProgramStore()
const authStore = useAuthStore()
const acl = useAcl()

const loading = ref<boolean>(false)
const towers = computed({
  get(): TowerModel[] {
    return props.programModel?.towers ?? []
  },
  async set(newValue: TowerModel[]) {
    const localProgram = cloneDeep(props.programModel as ProgramModel)
    if (!localProgram.towers) {
      localProgram.towers = []
    }
    localProgram.towers = newValue
    programStore.$patch({
      isProgramEditable: true,
      program: localProgram
    })

    // we also need to update the exposure's tower structure to be in sync with the program everytime it changes
    const localExposures = cloneDeep(props.exposuresModel as ExposureModel[])
    localExposures.forEach((exposure) => {
      const originalTower: TowerModel[] = exposure.towers ?? []
      exposure.towers = cloneDeep(localProgram.towers)
      // merge layerTarget if it has values set from original tower
      exposure.towers?.forEach((tower: TowerModel) => {
        const originalTowerItem = originalTower?.find((t) => t.id === tower.id)
        getAllTowerLayers(tower).forEach((layer) => {
          const originalLayer = getAllTowerLayers(originalTowerItem).find((l) => l.id === layer.id)
          if (
            originalLayer &&
            originalLayer.layerTarget &&
            (originalLayer.layerTarget.participation || originalLayer.layerTarget.premium || originalLayer.layerTarget.rate)
          ) {
            layer.layerTarget = originalLayer.layerTarget
          }
        })
      })
    })
    emit('update:exposuresModel', localExposures)
    await nextTick()
    await triggerSave()
  }
})

async function triggerSave() {
  loading.value = true
  await handleSave({ programModel: props.programModel, exposuresModel: props.exposuresModel } as HandleSavePayloadType)
  loading.value = false
}

const tower = computed({
  get(): TowerModel {
    return towers.value.find((tower) => tower.id === towerStore.activeTowerId)! ?? ({} as TowerModel)
  },
  set(newValue: TowerModel): void {
    towers.value = towers.value.map((e: TowerModel) => {
      if (e.id === newValue.id) return newValue
      return e
    })
  }
})

async function towerModifyStructureClicked() {
  const program = await programService.readProgramById({ programId: props.programModel?.id! })
  programStore.$patch({
    program,
    isProgramEditable: true
  })
}

// const activeTower = computed(() => towers.value.find((tower) => tower.id === towerStore.activeTower))
</script>

<template>
  <div class="space-y-2 p-1">
    <TowerSchematicComponent v-model:towers="towers" />
    <Suspense>
      <TowerModifyStructure
        v-if="showAddTowerButton && acl.permission('program-write', programModel as AbilityArgs)"
        v-model:towers="towers"
        @open="towerModifyStructureClicked"
      />
    </Suspense>
    <transition
      enter-active-class="transition duration-100 ease-out"
      enter-from-class="transform scale-x-0 opacity-0"
      enter-to-class="transform scale-x-100 opacity-100"
      leave-active-class="transition duration-75 ease-out"
      leave-from-class="transform scale-x-100 opacity-100"
      leave-to-class="transform scale-x-0 opacity-0"
    >
      <div v-if="programStore.isProgramEditable" class="flex justify-center">
        <rq-btn
          :class="cn('flex items-center space-x-2')"
          datacy="schematicSaveButton"
          :loading="loading"
          :style="{ border: `1px solid ${colors.primary['400']} !important` }"
          variant="primary-outline"
          @click="triggerSave"
        >
          <rq-icon icon="lucide:save" />
          <span>Save Changes</span>
        </rq-btn>
      </div>
    </transition>
    <ProgramIndicationAssignSelectLayer
      v-if="showAssignMarketButton && acl.permission('program-write', programModel as AbilityArgs)"
      :program="programModel"
    />
    <template v-if="acl.anyRole(['rs', 'admin'])">
      <TowerTermsAndConditionsNavigation v-if="authStore.allProgramSectionsVisibility === 'hide'" v-model:tower="tower" />
    </template>
  </div>
</template>

<style lang="scss" scoped></style>
