<script lang="ts">
import type { LineItemDtoTypeEnum } from 'typescript-core-api-client/dist/api'

export type Props = {
  items?: LineItemDtoTypeEnum[]
  contentClass?: ''
}
</script>

<script setup lang="ts">
import type { PropType } from 'vue'
import { computed, ref } from 'vue'

import { cn, twMerge } from '@/lib/utils'

import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuTrigger
} from '@/component/arqu-components/shadcn/ui/dropdown-menu'
import { Label } from '@/component/arqu-components/shadcn/ui/label'
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/component/arqu-components/shadcn/ui/tooltip'

const props = withDefaults(defineProps<Props>(), {
  items: () => [],
  contentClass: ''
})

const TRUNCATE_LENGTH = 48

const lineItems = defineModel({ type: Array as PropType<LineItemDtoTypeEnum[]>, required: false, default: () => [] })

const open = ref<boolean>(false)

function handleOpen(e: boolean) {
  if (e) {
    open.value = e
  }
}

const displayText = computed(() => {
  if (!lineItems.value.length) {
    return 'Select Multiple'
  }
  return props.items.filter((i) => !!i && lineItems.value.includes(i)).join(',\n')
})

function isSelected(lineItem: LineItemDtoTypeEnum): boolean {
  return lineItems.value.includes(lineItem)
}

function handleSelect(lineItem: LineItemDtoTypeEnum) {
  if (isSelected(lineItem)) {
    lineItems.value = lineItems.value.filter((d) => d !== lineItem)
  } else {
    lineItems.value = [...lineItems.value, lineItem]
  }
}

const allSelected = computed(() => lineItems.value.length > 0 && lineItems.value.length === props.items.length)
const someSelected = computed(() => lineItems.value.length > 0 && !allSelected.value)

const allChecked = computed(() => {
  if (allSelected.value) return true
  if (someSelected.value) return 'indeterminate'
  return false
})

function handleAllChecked() {
  if (allSelected.value) {
    lineItems.value = []
  } else {
    lineItems.value = [...props.items]
  }
}
</script>

<template>
  <div>
    <Label>Line Item Type</Label>
    <DropdownMenu :open="open" @update:open="handleOpen">
      <DropdownMenuTrigger as-child>
        <rq-btn
          :class="twMerge('flex w-full items-center justify-between', lineItems.length ? 'text-gray-950' : 'text-gray-500')"
          :disabled="!items.length"
          variant="trigger"
        >
          <TooltipProvider :disabled="displayText.length < TRUNCATE_LENGTH">
            <Tooltip>
              <TooltipTrigger as-child>
                <span class="truncate">{{ displayText }}</span>
              </TooltipTrigger>
              <TooltipContent align="start" as="ul" class="max-w-[400px] flex-wrap">
                <li v-for="item in lineItems" :key="item" class="block">{{ item }}</li>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
          <rq-icon icon="lucide:chevron-down" />
        </rq-btn>
      </DropdownMenuTrigger>
      <DropdownMenuContent
        align="start"
        :class="cn('max-h-64 w-full min-w-[400px] overflow-y-auto', contentClass)"
        @interact-outside="open = false"
      >
        <DropdownMenuCheckboxItem :checked="allChecked" class="border-b" @click.prevent="handleAllChecked">
          Select All
        </DropdownMenuCheckboxItem>
        <DropdownMenuCheckboxItem
          v-for="item in items"
          :key="item"
          :checked="isSelected(item as LineItemDtoTypeEnum)"
          :value="item"
          @click.prevent="handleSelect(item as LineItemDtoTypeEnum)"
        >
          {{ item }}
        </DropdownMenuCheckboxItem>
      </DropdownMenuContent>
    </DropdownMenu>
  </div>
</template>

<style scoped lang="scss"></style>
