import { getLogger } from '@/composables/util/log'

import type { MappedContactViewModel } from '@/capability/contact/types'
import type { DealTeamViewModel } from '@/capability/deal/types'
import type { DealModel } from '@/capability/deal/types/deal-model'
import { resourceModelFactory } from '@/capability/resource/ResourceModelFactory'
import { apiConfiguration } from '@/capability/system/Config'
import type {
  ArchiveDealsRequestReasonTypeEnum,
  BatchShareDealRequest,
  BatchShareDealResponse,
  CreateDealRequest,
  CreateDealResponse,
  DealNotificationRequest,
  GrantUserGroupPrivilegesRequest,
  ShareDealRequest,
  ShareDealResponse,
  UpdateDealRequest
} from 'typescript-core-api-client'
import { DealApi } from 'typescript-core-api-client'
import type {
  DealNotificationUserGroupRequest,
  ShareDealUserGroupRequest,
  UnshareDealUserGroupRequest
} from 'typescript-core-api-client/dist/api'
import type { Configuration as CoreApiConfiguration } from 'typescript-core-api-client/dist/configuration'

export type ShareDealPayloadType = {
  dealId: string
  shareDealRequest: ShareDealRequest
}

export type ShareDealBatchPayloadType = {
  dealId: string
  batchShareDealRequest: BatchShareDealRequest
}

export type ShareDealTeamsPayloadType = {
  dealId: string
  request: ShareDealUserGroupRequest
}

export type UpdateNotificationPayloadType = {
  dealId: string
  request: DealNotificationRequest
}

export type UpdateNotificationForUserGroupPayloadType = {
  dealId: string
  userGroupId: string
  request: DealNotificationUserGroupRequest
}

export type ShareDealWithContactsPayloadType = {
  contacts: MappedContactViewModel[]
  customMessage: string
  dealId: string
  teams: DealTeamViewModel[]
  ccEmails: string[]
  notify: boolean
}

export type UnshareDealFromUsersPayloadType = {
  dealId: string
  userIds: string[]
}

export type UnshareDealFromUserGroupsPayloadType = {
  dealId: string
  request: UnshareDealUserGroupRequest
}

export type RenewalPayloadType = {
  dealId: string
  programIds: string[]
  assignedUsers: string[]
}

export type ArchivePayloadType = {
  dealIds: string[]
  reason: ArchiveDealsRequestReasonTypeEnum
  comment?: string
}

export type RevokePrivilegesPayloadType = {
  userId: string
  privilegeNames: string[]
}

export interface DealWriteService {
  createDeal: (payload: CreateDealRequest) => Promise<CreateDealResponse>
  update: (deal: DealModel) => Promise<DealModel>
  shareDeal: (payload: ShareDealPayloadType) => Promise<ShareDealResponse>
  shareDealBatch: (payload: ShareDealBatchPayloadType) => Promise<BatchShareDealResponse>
  shareDealTeams: (payload: ShareDealTeamsPayloadType) => Promise<void>
  updateNotification: (payload: UpdateNotificationPayloadType) => Promise<void>
  updateNotificationForUserGroup: (payload: UpdateNotificationForUserGroupPayloadType) => Promise<void>
  shareDealWithContacts: (payload: ShareDealWithContactsPayloadType) => Promise<void>
  shareDealWithUserGroups: (payload: GrantUserGroupPrivilegesRequest) => Promise<void>
  unshareDealFromUsers: (payload: UnshareDealFromUsersPayloadType) => Promise<void>
  unshareDealFromUserGroups: (payload: UnshareDealFromUserGroupsPayloadType) => Promise<void>
  renewal: (payload: RenewalPayloadType) => Promise<DealModel>
  archive: (payload: ArchivePayloadType) => Promise<void>
  revokePrivileges: (payload: RevokePrivilegesPayloadType) => Promise<void>
}

const logger = getLogger('dealWriteService')

export const dealWriteService = {
  createDeal: async function (payload: CreateDealRequest): Promise<CreateDealResponse> {
    const config: CoreApiConfiguration = await apiConfiguration()
    const dealApi = new DealApi(config)

    const response = await dealApi.createDeal(payload)
    return response.data
  },
  update: async function (deal: DealModel): Promise<DealModel> {
    const config: CoreApiConfiguration = await apiConfiguration()
    const dealApi = new DealApi(config)

    const updateDealRequest: UpdateDealRequest = {}
    updateDealRequest.deal = deal
    const response = await dealApi.update(deal.id!, updateDealRequest)
    return resourceModelFactory.buildDealFromDtoViaMoveSemantics(response.data.deal!, false)
  },
  shareDeal: async function (payload: ShareDealPayloadType): Promise<ShareDealResponse> {
    const config: CoreApiConfiguration = await apiConfiguration()
    const dealApi = new DealApi(config)

    const { dealId, shareDealRequest } = payload
    const response = await dealApi.share(dealId, shareDealRequest)
    return response.data
  },
  shareDealBatch: async function (payload: ShareDealBatchPayloadType): Promise<BatchShareDealResponse> {
    const config: CoreApiConfiguration = await apiConfiguration()
    const dealApi = new DealApi(config)

    const { dealId, batchShareDealRequest } = payload
    const response = await dealApi.shareBatch(dealId, batchShareDealRequest)
    return response.data
  },
  shareDealTeams: async function (payload: ShareDealTeamsPayloadType): Promise<void> {
    const config: CoreApiConfiguration = await apiConfiguration()
    const dealApi = new DealApi(config)

    const { dealId, request } = payload
    await dealApi.shareUserGroup(dealId, request)
  },
  updateNotification: async function (payload: UpdateNotificationPayloadType): Promise<void> {
    const config: CoreApiConfiguration = await apiConfiguration()
    const dealApi = new DealApi(config)

    const { dealId, request } = payload
    await dealApi.notification(dealId, request)
  },
  updateNotificationForUserGroup: async function (payload: UpdateNotificationForUserGroupPayloadType): Promise<void> {
    const config: CoreApiConfiguration = await apiConfiguration()
    const dealApi = new DealApi(config)

    const { dealId, userGroupId, request } = payload
    try {
      await dealApi.userGroupNotification(dealId, userGroupId, request)
    } catch (err) {
      logger.error(err as unknown as Error, { context: { method: 'updateNotificationForUserGroup' } })
    }
  },
  shareDealWithContacts: async function (payload: ShareDealWithContactsPayloadType): Promise<void> {
    const { contacts, customMessage, dealId, teams, ccEmails, notify } = payload
    const selectedContacts = contacts.filter((contact) => contact.selected)

    // filter by arqu contacts and update deal's config
    if (selectedContacts.length > 0) {
      const requests = [] as ShareDealRequest[]

      for (let index in selectedContacts) {
        // this contact is previously shared with already
        if (selectedContacts[index].readOnly) {
          continue
        }

        requests.push({
          email: selectedContacts[index].email,
          firstName: selectedContacts[index].name.split(' ')[0],
          lastName: selectedContacts[index].name.split(' ')[1],
          message: customMessage,
          ccEmails: ccEmails,
          programIds: []
        })
      }

      const batchResponse = await this.shareDealBatch({
        dealId,
        batchShareDealRequest: {
          requests,
          notify
        }
      })

      batchResponse.responses?.forEach((response: ShareDealResponse) => {
        const team = teams.find((team) => team.orgType === response.orgType)
        team?.users.push({
          id: response.userId!!,
          email: response.email!!,
          name: response.firstName + ' ' + response.lastName,
          orgName: response.orgName!!,
          orgType: response.orgType!!,
          dealPermissions: [],
          alertsOn: true,
          selected: false
        })
      })

      selectedContacts.forEach((contact) => {
        contact.readOnly = true
      })
    }
  },
  shareDealWithUserGroups: async function (payload: GrantUserGroupPrivilegesRequest): Promise<void> {
    const config: CoreApiConfiguration = await apiConfiguration()
    const dealApi = new DealApi(config)

    await dealApi.grantGroupPrivileges(payload)
  },
  unshareDealFromUsers: async function (payload: UnshareDealFromUsersPayloadType): Promise<void> {
    const config: CoreApiConfiguration = await apiConfiguration()
    const dealApi = new DealApi(config)

    const { dealId, userIds } = payload
    await dealApi.unshare(dealId, { userIds })
  },
  unshareDealFromUserGroups: async function (payload: UnshareDealFromUserGroupsPayloadType): Promise<void> {
    const config: CoreApiConfiguration = await apiConfiguration()
    const dealApi = new DealApi(config)

    const { dealId, request } = payload
    await dealApi.unshareUserGroup(dealId, request)
  },
  renewal: async function (payload: RenewalPayloadType): Promise<DealModel> {
    const config: CoreApiConfiguration = await apiConfiguration()
    const dealApi = new DealApi(config)

    const { dealId, programIds, assignedUsers } = payload
    const response = await dealApi.renewal({ dealId, programIds, assignedUsers })
    return resourceModelFactory.buildDealFromDtoViaMoveSemantics(response.data.deal!, false)
  },
  archive: async function (payload: ArchivePayloadType): Promise<void> {
    const config: CoreApiConfiguration = await apiConfiguration()
    const dealApi = new DealApi(config)

    const { dealIds, reason, comment } = payload
    await dealApi.archive({ dealIds, reasonType: reason, comment })
  },
  revokePrivileges: async function (payload: RevokePrivilegesPayloadType): Promise<void> {
    const config: CoreApiConfiguration = await apiConfiguration()
    const dealApi = new DealApi(config)

    const { userId, privilegeNames } = payload
    await dealApi.revokePrivileges({ userId, privilegeNames })
  }
} as DealWriteService
