<script setup lang="ts">
import type { PropType } from 'vue'
import { watch } from 'vue'
import { ref } from 'vue'

import { getLogger } from '@/composables/util/log/logger'
import { useNotificationStore } from '@/stores/notification'

import { type ChatLog } from '@/capability/document/types'

import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger
} from '@/component/arqu-components/shadcn/ui/dialog'
import { Label } from '@/component/arqu-components/shadcn/ui/label'
import { Textarea } from '@/component/arqu-components/shadcn/ui/textarea'
import { logChatEvent } from '@/component/chat/utils'

const props = defineProps({
  dealId: {
    type: String,
    required: false,
    default: ''
  },
  docId: {
    type: String,
    required: false,
    default: ''
  },
  chatLog: {
    type: Object as PropType<ChatLog>,
    required: true
  },
  disabled: {
    type: Boolean,
    required: false,
    default: false
  },
  active: {
    type: Boolean,
    required: false,
    default: false
  }
})

const emit = defineEmits<{
  (e: 'thumb:down'): void
}>()

const notificationStore = useNotificationStore()
const logger = getLogger('AIChatFeedbackDialog.vue')

const dialog = ref<boolean>(false)
const thumbUpOrDown = ref<'up' | 'down' | ''>('')
const comment = ref<string>('')

function handleSubmit() {
  if (thumbUpOrDown.value === '') {
    try {
      logChatEvent({
        chatLog: props.chatLog!,
        resourceCrudl: 'rating',
        resourceCrudlResult: thumbUpOrDown.value ? 'thumbs-up' : 'thumbs-down',
        dealId: props.dealId,
        documentId: props.docId,
        comment: comment.value
      })
      thumbUpOrDown.value = 'down'
      dialog.value = false
      emit('thumb:down')
      notificationStore.publishSuccessMessage('Thank you for your feedback!')
    } catch (err) {
      notificationStore.publishOneOrMoreErrUnhandled(err as unknown as Error)
    }
  }
}

watch(dialog, (value) => {
  if (value) {
    comment.value = ''
  } else {
    if (thumbUpOrDown.value === '') {
      notificationStore.publishByType({ message: 'Your feedback has not been recorded.', type: 'info' })
    }
  }
})
</script>

<template>
  <Dialog v-model:open="dialog">
    <DialogTrigger as-child>
      <rq-btn :disabled="disabled" icon="square" size="lg" :variant="active ? 'primary' : 'ghost'">
        <rq-icon :color="active ? 'white' : 'current'" icon="lucide:thumbs-down" size="small" />
      </rq-btn>
    </DialogTrigger>
    <DialogContent
      class="z-[251] sm:ml-10 sm:w-[50vw] xl:w-[40vw]"
      overlay-class="z-[251]"
      @escape-key-down.prevent=""
      @interact-outside.prevent=""
      @pointer-down-outside.prevent=""
    >
      <DialogHeader>
        <DialogTitle>Additional Feedback</DialogTitle>
        <DialogDescription>We value your opinion. What's missing?</DialogDescription>
      </DialogHeader>
      <form @submit.prevent="handleSubmit">
        <div>
          <Label class="flex justify-between" for="comment">
            <span>Comment</span>
            <span class="text-gray-400">(Optional)</span>
          </Label>
          <Textarea v-model="comment" />
        </div>
        <DialogFooter class="pt-4">
          <rq-btn type="submit" variant="primary">Submit</rq-btn>
        </DialogFooter>
      </form>
    </DialogContent>
  </Dialog>
</template>
