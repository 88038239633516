import cloneDeep from 'lodash.clonedeep'

import { getLogger } from '@/composables/util/log/logger'
import { isPresent, undefineIfEmpty } from '@/lib/utils/formatting'
import { generateUuid } from '@/lib/utils/id'
import { isCarrier, isRiskSpecialist } from '@/lib/utils/user'
import { useAuthStore } from '@/stores/auth'

import { PAGE_VIEW_CODE, PAGE_VIEW_WORKER_ACTION, PROGRAMMATIC_CODE, USER_ACTIVITY_CODE } from '@/capability/event/eventUtil'
import type { SystemEventDto } from '@/capability/event/SystemEventDto'

export interface SystemEventCaptureService {
  fireAndForgetSystemEvent(systemEventDto: SystemEventDto): void
  fireAndForgetPageView(systemEventDto: SystemEventDto): void
  fireAndForgetUserActivity(systemEventDto: SystemEventDto): void
  fireAndForgetProgrammaticEvent(systemEventDto: SystemEventDto): void
}

export const systemEventCaptureService = {
  fireAndForgetSystemEvent: function (systemEventDto: SystemEventDto): void {
    const logger = getLogger('SystemEventCaptureServiceImpl')
    if (import.meta.env.VITE_SYSTEM_EVENT_CAPTURE_SERVICE_ENABLED_FLAG !== 'true') {
      logger.info(`SystemEventCaptureService is DISABLED per config.  Will not capture event`, {
        context: { method: 'fireAndForgetSystemEvent' }
      })
      return
    }

    const workingSystemEventDto = cloneDeep(systemEventDto)

    workingSystemEventDto.id = generateUuid()

    workingSystemEventDto.eventSchemaVersion = 'v1'

    const authStore = useAuthStore()
    if (authStore.user != null) {
      workingSystemEventDto.signedInUserId = authStore.user.id
      workingSystemEventDto.signedInOrganizationId = authStore.user.organizationId
      workingSystemEventDto.snapshotUserBlurb = undefineIfEmpty(
        [authStore.user.email, authStore.user.firstName, authStore.user.lastName].filter((x) => isPresent(x)).join(', ')
      )

      // I think this is all we know about the organization?
      workingSystemEventDto.snapshotOrganizationBlurb = authStore.user.organizationId

      workingSystemEventDto.carrierFlag = isCarrier(authStore.user)

      if (isRiskSpecialist(authStore.user)) {
        workingSystemEventDto.riskSpecialistUserId = workingSystemEventDto.signedInUserId
      }

      workingSystemEventDto.signedInOrganizationId = authStore.user.organizationId
    }
    workingSystemEventDto.authToken = authStore.accessToken
    workingSystemEventDto.appAgent = `arqu-web; ${[import.meta.env.VITE_GIT_BRANCH, undefineIfEmpty(import.meta.env.VITE_GIT_COMMIT_HASH)]
      .filter((x) => isPresent(x))
      .join('--')}` // whatever my version of arqu-web is
    workingSystemEventDto.appName = 'arqu-web'
    workingSystemEventDto.appVersion = undefineIfEmpty(
      `${[import.meta.env.VITE_GIT_BRANCH, undefineIfEmpty(import.meta.env.VITE_GIT_COMMIT_HASH)].filter((x) => isPresent(x)).join('--')}`
    )
    workingSystemEventDto.referrer = window.location.href
    workingSystemEventDto.screenWidth = screen.width
    workingSystemEventDto.screenHeight = screen.height

    workingSystemEventDto.resourceInstanceName = undefineIfEmpty(workingSystemEventDto.resourceInstanceName)
    workingSystemEventDto.snapshotUserBlurb = undefineIfEmpty(workingSystemEventDto.snapshotUserBlurb)
    workingSystemEventDto.authToken = undefineIfEmpty(workingSystemEventDto.authToken)
    workingSystemEventDto.appVersion = undefineIfEmpty(workingSystemEventDto.appVersion)

    const endpoint = import.meta.env.VITE_EVENT_REPORTING_ENDPOINT
    const headers = new Headers({ 'Content-Type': 'application/json; charset=UTF-8' })
    let sessionId = sessionStorage.getItem(`rq-session-${import.meta.env.VITE_ARQU_ENVIRONMENT}`)
    if (!sessionId) {
      sessionId = generateUuid()
      sessionStorage.setItem(`rq-session-${import.meta.env.VITE_ARQU_ENVIRONMENT}`, sessionId)
    }
    headers.append('X-Arqu-Session-Id', sessionId)

    setTimeout(async () => {
      try {
        await fetch(endpoint, {
          method: 'POST',
          body: JSON.stringify(workingSystemEventDto),
          headers
        })
      } catch (err) {
        logger.warn(err, {
          context: {
            method: 'fireAndForgetSystemEvent'
          }
        })
      }
    })
  },
  fireAndForgetPageView: function (systemEventDto: SystemEventDto): void {
    const workingSystemEventDto = cloneDeep(systemEventDto)
    workingSystemEventDto.workerAction = PAGE_VIEW_WORKER_ACTION
    workingSystemEventDto.code = PAGE_VIEW_CODE

    /* This was moved to eventproc */

    // if ( workingSystemEventDto.code == null || !workingSystemEventDto.code.startsWith(PAGE_VIEW_CODE)) {
    //   workingSystemEventDto.code = `${[PAGE_VIEW_CODE, workingSystemEventDto.code].filter(x => isPresent(x)).join("-")}`
    // }
    this.fireAndForgetSystemEvent(workingSystemEventDto)
  },
  fireAndForgetUserActivity: function (systemEventDto: SystemEventDto): void {
    const workingSystemEventDto = cloneDeep(systemEventDto)
    workingSystemEventDto.code = USER_ACTIVITY_CODE
    this.fireAndForgetSystemEvent(workingSystemEventDto)
  },
  fireAndForgetProgrammaticEvent: function (systemEventDto: SystemEventDto): void {
    const workingSystemEventDto = cloneDeep(systemEventDto)
    workingSystemEventDto.code = PROGRAMMATIC_CODE
    this.fireAndForgetSystemEvent(workingSystemEventDto)
  }
} as SystemEventCaptureService
