<script setup lang="ts">
import { computed, onBeforeUnmount, ref, watch } from 'vue'
import { onClickOutside } from '@vueuse/core'
import JSConfetti from 'js-confetti'
import order_by from 'lodash.orderby'
import { z } from 'zod'

import { getLogger } from '@/composables/util/log'
import { useZod } from '@/composables/zod'
import type { ErrorType } from '@/lib/types'
import { useLineItemsStore } from '@/stores/line-item'
import { useNotificationStore } from '@/stores/notification'

import { LineItemDtoTypeEnum } from 'typescript-core-api-client/dist/api'

import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle
} from '@/component/arqu-components/shadcn/ui/dialog'

const lineItemsStore = useLineItemsStore()
const notificationStore = useNotificationStore()
const logger = getLogger('ProgramLineItemInputDialog.vue')

const jsConfetti = new JSConfetti()

const loading = ref<boolean>(false)
const itemsOpen = ref<boolean>(false)
const itemsList = ref()
const stateObject = {
  name: '',
  type: '',
  description: '',
  keep_open: false
}

const DESCRIPTION_COUNTER = 256
const LINE_ITEM_TYPES_ITEMS = [
  LineItemDtoTypeEnum.Sublimits,
  LineItemDtoTypeEnum.Deductibles,
  LineItemDtoTypeEnum.Form,
  LineItemDtoTypeEnum.SpecialTermAndConditions
]

const schemaObject = {
  name: z.string().nonempty('Name is required'),
  type: z
    .string()
    .min(1, 'Type is required')
    .refine((value) => Object.keys(LineItemDtoTypeEnum).includes(value), {
      message: 'Invalid type'
    }),
  description: z.optional(z.string().max(DESCRIPTION_COUNTER, `Description must be less than ${DESCRIPTION_COUNTER} characters`))
}

function objectRefineFunction(obj) {
  if (lineItemsStore.lineItemInputMode === 'edit') return true
  const existing_line_item_index = lineItemsStore.lineItemNamesAndTypes.findIndex(
    ({ name, type, status }) => name?.toLowerCase() === obj.name?.toLowerCase() && type === obj.type && status === 'Active'
  )
  return existing_line_item_index === -1
}

const objectRefineDetails = {
  message: 'A line item with that name and type already exists',
  path: []
}

const objectRefine = [
  {
    _function: objectRefineFunction,
    _details: objectRefineDetails
  }
]

const { state, errors, resetErrors, resetState, validate } = useZod<typeof stateObject, ErrorType<typeof stateObject>>({
  stateObject,
  schemaObject,
  objectRefine
})

async function handleSubmit() {
  const add = lineItemsStore.lineItemInputMode === 'add'
  try {
    resetErrors()
    loading.value = true
    const valid = validate()
    if (valid) {
      if (add) {
        await lineItemsStore.createLineItem({
          name: state.name,
          type: state.type,
          description: state.description
        })
      } else {
        await lineItemsStore.updateLineItem({
          id: lineItemsStore.lineItemEditId!,
          name: state.name,
          type: state.type,
          description: state.description
        })
      }
      jsConfetti.addConfetti({ emojis: ['🌯'] })
      if (!state.keep_open) {
        closeDialog()
      } else {
        resetState()
        resetErrors()
      }
    }
  } catch (err) {
    notificationStore.publishOneOrMoreErrUnhandled(err as unknown as Error)
  } finally {
    loading.value = false
  }
}

lineItemsStore.$subscribe((mutation, store_state) => {
  if (mutation.type === 'patch object' && mutation.storeId === 'line-items') {
    if (mutation.payload?.lineItemManagementDialog) {
      state.name = store_state.lineItemName
      state.type = store_state.lineItemType
      state.description = store_state.lineItemDescription
    } else {
      resetState()
      resetErrors()
    }
  }
})

function handleUpdateDialog(value: boolean): void {
  lineItemsStore.$patch({
    lineItemManagementDialog: value
  })
}

function closeDialog() {
  handleUpdateDialog(false)
}

const filteredItems = computed(() =>
  order_by(
    lineItemsStore.lineItems
      .filter((l) => (state.type ? l.type === state.type : true))
      .filter((l) => {
        if (!l.name) return false
        return l.name.toLowerCase().includes(state.name.toLowerCase())
      }),
    ['name', 'type'],
    ['asc', 'asc']
  )
)

watch(
  () => state.name,
  (value, oldValue) => {
    if (value && !itemsOpen.value) {
      if (value !== oldValue) {
        itemsOpen.value = true
      }
    } else if (!value && itemsOpen.value) {
      itemsOpen.value = false
    }
  }
)

const displayItems = computed(() => filteredItems.value.length > 0 && !isEditMode.value && itemsOpen.value)

const isEditMode = computed(() => lineItemsStore.lineItemInputMode === 'edit')

function handleClick() {
  jsConfetti.addConfetti({ emojis: ['🌯'] })
}

onBeforeUnmount(() => {
  jsConfetti.clearCanvas()
})

onClickOutside(itemsList, () => (itemsOpen.value = false))
</script>

<template>
  <Dialog :open="lineItemsStore.lineItemManagementDialog" @update:open="handleUpdateDialog">
    <DialogContent class="z-[52] w-[95vw] sm:w-[75vw] md:w-[65vw] lg:w-[45vw] xl:w-[35vw]" overlay-class="z-[51]">
      <DialogHeader>
        <DialogTitle>{{ lineItemsStore.lineItemInputMode === 'add' ? 'Add New' : 'Edit' }} Line Item</DialogTitle>
        <DialogDescription class="sr-only">Add or edit a line item here</DialogDescription>
      </DialogHeader>
      <form @submit.prevent="handleSubmit">
        <div class="space-y-2">
          <div class="relative">
            <rq-text-field
              v-model="state.name"
              id="name"
              :auto-focus="!isEditMode"
              autocomplete="off"
              clearable
              :disabled="isEditMode"
              :errors="errors.name"
              label="Name"
            />
            <transition leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100" leave-to-class="opacity-0">
              <ul
                v-if="displayItems"
                ref="itemsList"
                class="absolute z-[100] max-h-64 w-full divide-y overflow-y-auto rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
              >
                <li v-for="item in filteredItems" :key="`${item.id}`">
                  <button class="w-full select-none rounded px-4 py-2 text-start hover:bg-primary-100" type="button" @click="handleClick">
                    {{ item.name }}
                    <span class="text-sm italic text-gray-500">({{ item.type }})</span>
                  </button>
                </li>
              </ul>
            </transition>
          </div>
          <rq-listbox-single
            v-model="state.type"
            id="type"
            content-class="z-[53]"
            :disabled="isEditMode"
            :errors="errors.type"
            :items="LINE_ITEM_TYPES_ITEMS"
            label="Type"
          />
          <rq-textarea
            v-model="state.description"
            :auto-focus="isEditMode"
            :counter="DESCRIPTION_COUNTER"
            :errors="errors.description"
            hint="Optional"
          />
          <div class="pt-2">
            <rq-checkbox
              v-if="lineItemsStore.lineItemInputMode === 'add'"
              v-model:checked="state.keep_open"
              id="keep-open"
              class="items-center"
              label="Create another line item"
            />
          </div>
          <rq-alert v-if="errors.field_errors.length" alert-type="error">{{ errors.field_errors[0] }}</rq-alert>
        </div>
        <DialogFooter class="space-y-2 pt-2 sm:space-x-2 sm:space-y-0">
          <div class="flex space-x-2">
            <rq-btn id="cancel-btn" :disabled="loading" type="button" variant="outline" @click="handleUpdateDialog(false)">Cancel</rq-btn>
            <rq-btn id="success-btn" class="space-x-2" datacy="dialogCardActionsSubmitButton" :loading type="submit" variant="primary">
              <rq-icon v-if="loading" class="animate-spin" icon="lucide:loader" />
              <span>Submit</span>
            </rq-btn>
          </div>
        </DialogFooter>
      </form>
    </DialogContent>
  </Dialog>
</template>

<style scoped lang="scss"></style>
