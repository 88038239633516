import type { AxiosResponse } from 'axios'

import { type DealModel } from '@/capability/deal/types/deal-model'
import { type ProgramMinimalModel } from '@/capability/program/ProgramMinimalModel'
import { type ProgramTemplateListModel } from '@/capability/program/ProgramTemplateListModel'
import { resourceModelFactory } from '@/capability/resource/ResourceModelFactory'
import { apiConfiguration } from '@/capability/system/Config'
import type { UserModel } from '@/capability/user/model'
import {
  DealApi,
  type GetDealsProgramsResponse,
  type GetProgramResponse,
  ProgramApi,
  type ProgramDto,
  type ProgramMinimalDto
} from 'typescript-core-api-client'
import type { Resource, TemplateUploadResponseDto } from 'typescript-core-api-client/dist/api'
import type { Configuration as CoreApiConfiguration } from 'typescript-core-api-client/dist/configuration'

import type { ProgramModel, ProgramView } from './ProgramModel'

export type ReadProgramByIdPayloadType = {
  programId: string
  options?: {
    cacheControl: string
  }
}
export type ReadProgramsByDealIdPayloadType = {
  dealId: string
}
export type ReadProgramsByDealPayloadType = {
  deal: DealModel
}
export type UpdatePayloadType = {
  program: ProgramModel
}
export type CreatePayloadType = {
  program: ProgramModel
}
export type CopyPayloadType = {
  newName: string
  programId: string
}
export type RenamePayloadType = {
  newName: string
  programId: string
}
export type DeletePayloadType = {
  programId: string
}
export type PublishToRetailerPayloadType = {
  program: ProgramModel
  view: ProgramView
}
export type ExportAsExcelPayloadType = {
  program: ProgramModel
}
export type ExportAsPdfPayloadType = {
  program: ProgramModel
}
export type CreateProgramTemplatePayloadType = {
  program: ProgramModel
}
export type CreateProgramFromTemplatePayloadType = {
  dealId: string
  newProgramName: string
  programTemplateId: string
}
export type DeleteProgramTemplatePayloadType = {
  programTemplateId: string
}
export type UploadTemplateFilePayloadType = {
  templateType: string
  files: File[]
}

export type DownloadAsExcelPayloadType = {
  id: string
}

export type ListPrivilegeUsersPayloadType = {
  programId: string
}

export interface ProgramService {
  readProgramById: (payload: ReadProgramByIdPayloadType) => Promise<ProgramModel>
  readProgramsByDealId: (payload: ReadProgramsByDealIdPayloadType) => Promise<ProgramMinimalModel[]>
  readProgramsByDeal: (payload: ReadProgramsByDealPayloadType) => Promise<ProgramMinimalModel[]>
  update: (payload: UpdatePayloadType) => Promise<ProgramModel>
  create: (payload: CreatePayloadType) => Promise<ProgramModel>
  copy: (payload: CopyPayloadType) => Promise<ProgramModel>
  rename: (payload: RenamePayloadType) => Promise<ProgramModel>
  delete: (payload: DeletePayloadType) => Promise<void>
  publishToRetailer: (payload: PublishToRetailerPayloadType) => Promise<void>
  exportAsExcel: (payload: ExportAsExcelPayloadType) => Promise<AxiosResponse>
  exportAsPdf: (payload: ExportAsPdfPayloadType) => Promise<AxiosResponse>
  createProgramTemplate: (payload: CreateProgramTemplatePayloadType) => Promise<void>
  createProgramFromTemplate: (payload: CreateProgramFromTemplatePayloadType) => Promise<ProgramModel>
  listProgramTemplates: () => Promise<ProgramTemplateListModel[]>
  deleteProgramTemplate: (payload: DeleteProgramTemplatePayloadType) => Promise<void>
  uploadTemplateFile: (payload: UploadTemplateFilePayloadType) => Promise<TemplateUploadResponseDto[]>
  downloadAsExcel: (payload: DownloadAsExcelPayloadType) => Promise<Resource>
  downloadAsExcelBase64: (payload: DownloadAsExcelPayloadType) => Promise<string>
  listPrivilegeUsers: (payload: ListPrivilegeUsersPayloadType) => Promise<UserModel[]>
}

async function fetchGetProgramResponse(programId: string, options?: { cacheControl: string }): Promise<GetProgramResponse> {
  let config: CoreApiConfiguration
  if (options) {
    config = await apiConfiguration({ options })
  } else {
    config = await apiConfiguration()
  }
  const programApi = new ProgramApi(config)
  const response = await programApi.get(programId)
  return response.data
}

async function fetchGetDealsProgramsResponse(dealId: string): Promise<GetDealsProgramsResponse> {
  const config = await apiConfiguration()
  const dealApi = new DealApi(config)
  const response = await dealApi.getPrograms(dealId)
  return response.data
}

export const programService = {
  readProgramById: async function (payload: ReadProgramByIdPayloadType): Promise<ProgramModel> {
    const { programId, options } = payload
    const response = await fetchGetProgramResponse(programId, options)
    return resourceModelFactory.buildProgramFromDtoViaMoveSemantics(response.program!)
  },
  readProgramsByDealId: async function (payload: ReadProgramsByDealIdPayloadType): Promise<ProgramMinimalModel[]> {
    const { dealId } = payload
    const response = await fetchGetDealsProgramsResponse(dealId)
    return response.programs?.map((programDto: ProgramMinimalDto) => resourceModelFactory.buildProgramMinimal(programDto)) || []
  },
  readProgramsByDeal: async function (payload: ReadProgramsByDealPayloadType): Promise<ProgramMinimalModel[]> {
    const { deal } = payload
    const dealId: string = deal.id!
    return await this.readProgramsByDealId({ dealId })
  },
  update: async function (payload: UpdatePayloadType): Promise<ProgramModel> {
    const config = await apiConfiguration()
    const programApi = new ProgramApi(config)

    const { program } = payload
    const response = await programApi.updateProgram({ program: program as ProgramDto })
    return resourceModelFactory.buildProgramFromDtoViaMoveSemantics(response.data.program!)
  },
  create: async function (payload: CreatePayloadType): Promise<ProgramModel> {
    const config = await apiConfiguration()
    const programApi = new ProgramApi(config)

    const { program } = payload
    const response = await programApi.create({ program: program as ProgramDto })
    return resourceModelFactory.buildProgramFromDtoViaMoveSemantics(response.data.program!)
  },
  copy: async function (payload: CopyPayloadType): Promise<ProgramModel> {
    const config = await apiConfiguration()
    const programApi = new ProgramApi(config)

    const { newName, programId } = payload
    const response = await programApi.createProgramCopy({ newProgramName: newName, programId })
    return resourceModelFactory.buildProgramFromDtoViaMoveSemantics(response.data.program!)
  },
  rename: async function (payload: RenamePayloadType): Promise<ProgramModel> {
    const config = await apiConfiguration()
    const programApi = new ProgramApi(config)

    const { newName, programId } = payload
    const programResponse = await programApi.get(programId)
    const program = resourceModelFactory.buildProgramFromDtoViaMoveSemantics(programResponse.data.program!)
    program.title = newName
    const response = await programApi.updateProgram({ program: program as ProgramDto })
    return resourceModelFactory.buildProgramFromDtoViaMoveSemantics(response.data.program!)
  },
  delete: async function (payload: DeletePayloadType): Promise<void> {
    const config = await apiConfiguration()
    const programApi = new ProgramApi(config)

    const { programId } = payload
    await programApi._delete(programId)
  },
  publishToRetailer: async function (payload: PublishToRetailerPayloadType): Promise<void> {
    const config = await apiConfiguration()
    const programApi = new ProgramApi(config)

    const { program, view } = payload
    await programApi.publishProgramToRetailer({ programId: program.id!, privilege: String(view) })
  },
  exportAsExcel: async function (payload: ExportAsExcelPayloadType): Promise<AxiosResponse> {
    const config = await apiConfiguration()
    const programApi = new ProgramApi(config)

    const { program } = payload
    return (await programApi.downloadAsExcel(program.id!, { responseType: 'blob' })) as AxiosResponse
  },
  exportAsPdf: async function (payload: ExportAsPdfPayloadType): Promise<AxiosResponse> {
    const config = await apiConfiguration()
    const programApi = new ProgramApi(config)

    const { program } = payload
    return (await programApi.downloadAsPdf(program.id!, { responseType: 'blob' })) as AxiosResponse
  },
  createProgramTemplate: async function (payload: CreateProgramTemplatePayloadType): Promise<void> {
    const config = await apiConfiguration()
    const programApi = new ProgramApi(config)

    const { program } = payload
    await programApi.createProgramTemplate({ program: program as ProgramDto, programStructure: {} })
  },
  createProgramFromTemplate: async function (payload: CreateProgramFromTemplatePayloadType): Promise<ProgramModel> {
    const config = await apiConfiguration()
    const programApi = new ProgramApi(config)

    const response = await programApi.createProgramFromTemplate(payload)
    return resourceModelFactory.buildProgramFromDtoViaMoveSemantics(response.data.program!)
  },
  listProgramTemplates: async function (): Promise<ProgramTemplateListModel[]> {
    const config = await apiConfiguration()
    const programApi = new ProgramApi(config)

    const response = await programApi.listProgramTemplate()
    return response.data.programTemplates ?? []
  },
  deleteProgramTemplate: async function (payload: DeleteProgramTemplatePayloadType): Promise<void> {
    const config = await apiConfiguration()
    const programApi = new ProgramApi(config)

    const { programTemplateId } = payload
    await programApi.deleteProgramTemplate(programTemplateId)
  },
  uploadTemplateFile: async function (payload: UploadTemplateFilePayloadType): Promise<TemplateUploadResponseDto[]> {
    const config = await apiConfiguration()
    const programApi = new ProgramApi(config)

    const { files, templateType } = payload
    const formData = new FormData()
    for (const file of files) {
      formData.append('file', file)
    }
    // @ts-ignore - the generated upload api is slightly different
    const response = await programApi.createTemplateFromFileUpload(templateType, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
    return response.data
  },
  downloadAsExcel: async function (payload: DownloadAsExcelPayloadType): Promise<Resource> {
    const config: CoreApiConfiguration = await apiConfiguration()
    const programApi = new ProgramApi(config)

    const { id } = payload
    const response = await programApi.downloadAsExcel(id, { responseType: 'blob' })
    return response.data
  },
  downloadAsExcelBase64: async function (payload: DownloadAsExcelPayloadType): Promise<string> {
    const config: CoreApiConfiguration = await apiConfiguration()
    const programApi = new ProgramApi(config)

    const { id } = payload
    const response = await programApi.downloadAsExcelBase64(id)
    return response.data.content || ''
  },
  listPrivilegeUsers: async function (payload: ListPrivilegeUsersPayloadType): Promise<UserModel[]> {
    const config: CoreApiConfiguration = await apiConfiguration()
    const programApi = new ProgramApi(config)

    const { programId } = payload
    const response = await programApi.listPrivilegeUsers(programId)
    return response.data.users ?? []
  }
} as ProgramService
