<script setup lang="ts">
import { reactive, ref, watch } from 'vue'
import get from 'lodash.get'
import lodashSet from 'lodash.set'
import { z } from 'zod'

import { useNotificationStore } from '@/stores/notification'

import type { OrganizationModel } from '@/capability/organization/model'
import { organizationService } from '@/capability/organization/OrganizationService'
import type { OrganizationDtoTypeEnum } from 'typescript-core-api-client/dist/api'

import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle, DialogTrigger } from '@/component/arqu-components/shadcn/ui/dialog'

const notificationStore = useNotificationStore()

const props = defineProps({
  addButtonText: {
    type: String,
    required: false,
    default: 'Add Organization'
  },
  title: {
    type: String,
    required: false,
    default: 'Add New Organization'
  },
  organizationType: {
    type: String,
    required: false,
    default: undefined
  },
  disabled: {
    type: Boolean,
    required: false,
    default: false
  }
})

const emit = defineEmits<{ (e: 'updateOrganization', response: any): void }>()

const dialog = ref<boolean>(false)
const loading = ref<boolean>(false)

const organization = reactive<OrganizationModel>({
  type: '',
  domain: '',
  name: '',
  primaryContact: {
    email: '',
    firstName: '',
    lastName: ''
  }
} as OrganizationModel)

const zod_object = {
  name: z.string().min(1, 'Name is required'),
  primaryContact: z.object({
    firstName: z.string().min(1, 'First Name is required'),
    email: z.string().min(1, 'Email is required').email('Email is invalid')
  })
}

type ErrorType = {
  name: string[]
  primaryContact: {
    firstName: string[]
    email: string[]
  }
}

const errors = ref<ErrorType>({
  name: [],
  primaryContact: {
    firstName: [],
    email: []
  }
})

const resetErrors = () => {
  errors.value = {
    name: [],
    primaryContact: {
      firstName: [],
      email: []
    }
  }
}

const handleSubmit = async () => {
  try {
    loading.value = true
    resetErrors()
    const schema = z.object(zod_object)
    schema.parse(organization)
    //TODO need to support picking a organization type
    if (props.organizationType) {
      organization.type = props.organizationType as OrganizationDtoTypeEnum
    }
    organization.domain = (organization.primaryContact?.email ?? '').split('@')[1]
    const addedOrganization = await organizationService.create(organization)
    emit('updateOrganization', addedOrganization)

    notificationStore.publishSuccessMessage('Organization created successfully')
    dialog.value = false
  } catch (e) {
    if (e instanceof z.ZodError) {
      for (const issue of e.issues) {
        const path = issue.path.join('.')
        const value = get(errors.value, path)
        lodashSet(errors.value, path, [...value, issue.message])
      }
    } else {
      notificationStore.publishOneOrMoreErrUnhandled(e)
    }
  } finally {
    loading.value = false
  }
}

watch(dialog, () => {
  resetErrors()
})
</script>

<template>
  <Dialog v-model:open="dialog">
    <DialogTrigger as-child>
      <rq-btn id="createOrgBtn" variant="primary-outline">
        {{ addButtonText }}
      </rq-btn>
    </DialogTrigger>
    <DialogContent @escape-key-down.prevent="" @interact-outside.prevent="" @pointer-down-outside.prevent="">
      <form @submit.prevent="handleSubmit">
        <DialogHeader>
          <DialogTitle>
            {{ title }}
          </DialogTitle>
        </DialogHeader>
        <rq-text-field v-model="organization.name" id="organization-name" :errors="errors.name" label="Organization Name" />
        <div class="flex w-full space-x-2">
          <rq-text-field
            v-model="organization.primaryContact!.firstName"
            id="first-name"
            class="grow"
            :errors="get(errors, 'primaryContact.firstName', [])"
            label="Contact First Name"
          />
          <rq-text-field v-model="organization.primaryContact!.lastName" id="last-name" class="grow" label="Contact Last Name" />
        </div>
        <rq-text-field
          v-model="organization.primaryContact!.email"
          id="email"
          :errors="get(errors, 'primaryContact.email', [])"
          label="Contact Email"
        />
        <DialogFooter class="space-y-2 pt-2 sm:space-x-2 sm:space-y-0">
          <div class="flex space-x-2">
            <rq-btn
              id="cancel-btn"
              :disabled="loading"
              :style="{ border: '1px solid rgb(229 231 235) !important' }"
              type="button"
              variant="outline"
              @click="dialog = false"
            >
              Cancel
            </rq-btn>
            <rq-btn id="success-btn" datacy="dialogCardActionsSubmitButton" :loading="loading" type="submit" variant="primary">
              {{ addButtonText }}
            </rq-btn>
          </div>
        </DialogFooter>
      </form>
    </DialogContent>
  </Dialog>
</template>

<style lang="scss" scoped></style>
