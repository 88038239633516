<script setup lang="ts">
import { computed } from 'vue'

import { twMerge } from '@/lib/utils'
import { truncateText } from '@/lib/utils/formatting'

import type { LayerModel } from '@/capability/layer/LayerModel'

const props = defineProps<{
  layer: LayerModel
}>()

const emit = defineEmits<{
  (e: 'convertToNonPlug', id: string): void
}>()

const truncateLength = 13
const name = computed(() => props.layer.getNameWithDefault() ?? '')
const tooltipDisabled = computed(() => name.value.length <= truncateLength)
const limit = computed(() => (props.layer.limit ?? '').toLocaleString())
const excess = computed(() => (props.layer.excess ?? '').toLocaleString())

const handleClick = () => {
  emit('convertToNonPlug', props.layer.id!)
}
</script>

<template>
  <div :class="twMerge('flex justify-between', $attrs.class)">
    <ul class="text-start">
      <li class="text-xs">
        Name: {{ truncateText(name, truncateLength) }}
        <rq-tooltip v-if="!tooltipDisabled" :text="name" />
      </li>
      <li class="text-2xs">Start: {{ excess }}</li>
      <li class="text-2xs">Limit: {{ limit }}</li>
    </ul>
    <div class="text-end text-xs">
      <div>Auto-Generated Layer</div>
      <rq-btn size="xs" type="button" variant="primary-outline" @click="handleClick">Click to Modify</rq-btn>
    </div>
  </div>
</template>

<style scoped lang="scss"></style>
