import { getRiskAttributesRoutes } from '@/router/risk-attributes'

export default {
  path: '/retailer',
  meta: {
    access: {
      roles: ['retailer']
    }
  },
  children: [
    {
      path: '',
      redirect: { name: 'DealListView-Retailer' },
      access: {
        roles: ['retailer']
      }
    },
    {
      path: 'deals',
      children: [
        {
          path: '',
          name: 'DealListView-Retailer',
          component: () => import('@/views/deal/DealList.vue'),
          meta: {
            pageViewDetails: {
              pageId: 'deal-retailer-list',
              resourceType: 'deal',
              resourceCrudl: 'list',
              title: 'Deal Hub'
            },
            requiresAuth: true,
            title: 'Deal Hub',
            access: {
              roles: ['retailer']
            }
          }
        },
        {
          path: ':dealId',
          component: () => import('@/component/deal/DealIndividualSandboxComponent.vue'),
          children: [
            getRiskAttributesRoutes('Retailer'),
            {
              path: 'documents',
              name: 'DealDocuments-Retailer',
              component: () => import('@/views/documents/v2/DealDocumentsWrapper.vue'),
              meta: {
                ruleName: 'document-read',
                pageViewDetails: {
                  pageId: 'deal-document-list',
                  resourceType: 'deal-document',
                  resourceCrudl: 'list'
                },
                requiresAuth: true,
                title: 'Deal Documents',
                access: {
                  roles: ['retailer']
                }
              }
            },
            {
              path: 'messages',
              name: 'DealMessages-Retailer',
              component: () => import('@/views/messages/DealMessages.vue'),
              meta: {
                ruleName: 'messages-read',
                pageViewDetails: {
                  pageId: 'deal-messages-list',
                  resourceType: 'deal-message',
                  resourceCrudl: 'list'
                },
                requiresAuth: true,
                title: 'Deal Messages',
                access: {
                  roles: ['retailer']
                }
              }
            },
            {
              path: '',
              component: () => import('@/views/deal/DealDetail.vue'),
              name: 'DealDetailView-Retailer',
              meta: {
                ruleName: 'deal-read-info',
                pageViewDetails: {
                  pageId: 'deal-retailer-read',
                  resourceType: 'deal',
                  resourceCrudl: 'read',
                  title: 'Deal Manager'
                },
                title: 'Deal Manager',
                requiresAuth: true,
                access: {
                  roles: ['retailer']
                }
              },
              children: [
                {
                  path: ':programId',
                  component: () => import('@/views/deal/DealDetailProgram.vue'),
                  name: 'DealProgramDetailLegacy-Retailer',
                  meta: {
                    requiresAuth: true,
                    title: 'Deal Manager',
                    access: {
                      roles: ['retailer']
                    }
                  }
                }
              ]
            }
          ]
        }
      ]
    }
  ]
}
