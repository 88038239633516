<script setup lang="ts">
import { cn, toggleElement } from '@/lib/utils'
import { useProgramStore } from '@/stores/program'

import type { LineItemNavigationItemType } from '@/capability/program/types'
import { navigationItems } from '@/capability/program/utils'

import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/component/arqu-components/shadcn/ui/tooltip'

const programStore = useProgramStore()

const activeColor = (button: LineItemNavigationItemType): string =>
  programStore.visibleSection.includes(button.location) ? 'bg-orange-400 hover:bg-orange-500' : 'bg-transparent hover:bg-orange-100'
const activeButtonColor = (button: LineItemNavigationItemType): string =>
  programStore.visibleSection.includes(button.location) ? 'text-white' : 'text-orange-400'

const handleClick = (button: LineItemNavigationItemType): void => {
  programStore.visibleSection = toggleElement(programStore.visibleSection, button.location)
  // window.scrollTo({ top: 99999, behavior: 'smooth' })
}
</script>

<template>
  <div class="flex items-center space-x-1 pl-2">
    <template v-for="button in navigationItems" :key="button.name">
      <TooltipProvider :delay-duration="100">
        <Tooltip>
          <TooltipTrigger as-child>
            <rq-btn
              :id="`program-line-items-navigation-${button.location}`"
              :class="cn(activeColor(button))"
              :datacy="`line-items-navigation-${button.location}`"
              icon="square"
              size="lg"
              variant="ghost"
              @click="handleClick(button)"
            >
              <rq-icon :class="activeButtonColor(button)" :icon="button.icon" />
            </rq-btn>
          </TooltipTrigger>
          <TooltipContent>
            {{ button.name }}
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>
    </template>
  </div>
</template>

<style scoped lang="scss"></style>
