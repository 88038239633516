<script setup lang="ts">
import type { PropType } from 'vue'
import { computed, useAttrs, useSlots } from 'vue'

import { twMerge } from '@/lib/utils'

const props = defineProps({
  // detailsClass: {
  //   type: String,
  //   required: false,
  //   default: ''
  // },
  id: {
    type: String,
    required: true
  },
  errors: {
    type: Array as PropType<String[]>,
    required: false,
    default: () => []
  },
  hint: {
    type: String,
    required: false,
    default: ''
  },
  detailsRight: {
    type: String,
    required: false,
    default: ''
  },
  detailsRightClass: {
    type: String,
    required: false,
    default: ''
  }
})

const attrs = useAttrs()
const slots = useSlots()

const isErrorState = computed(() => props.errors?.length > 0)

const displayDetails = computed(
  () => isErrorState.value || props.hint?.length > 0 || (slots?.['details-right']?.()?.length ?? 0) > 0 || props.detailsRight?.length > 0
)
const detailsId = computed(() => (isErrorState.value ? `${props.id}-error` : `${props.id}-description`))
const detailsClass = computed(() => twMerge(isErrorState.value ? 'text-red-500' : 'text-gray-500', attrs['details-class']))
const detailsText = computed(() => (isErrorState.value ? props.errors?.[0] : props.hint))
</script>

<template>
  <div v-if="displayDetails" :class="twMerge('flex items-center justify-between pt-1', attrs.class)">
    <slot>
      <div :id="detailsId" :class="twMerge('ml-1 text-sm text-gray-500', detailsClass)">
        {{ detailsText }}
      </div>
    </slot>
    <slot name="details-right">
      <div :id="`${detailsId}-rhs`" :class="twMerge('mr-1 text-sm text-gray-500', detailsRightClass)">{{ detailsRight }}</div>
    </slot>
  </div>
</template>

<style scoped lang="scss"></style>
