import 'reflect-metadata'

import { plainToClassFromExist, plainToInstance, Type } from 'class-transformer'
import cloneDeep from 'lodash.clonedeep'

import { getLogger } from '@/composables/util/log/logger'

import { safeStringify } from '@/capability/json/jsonUtil'
import * as layerDtoUtil from '@/capability/layer/layerDtoUtil'
import type { LayerModel } from '@/capability/layer/LayerModel'
import type { LayerModelImmutable } from '@/capability/layer/LayerModelImmutable'
import type { LayerTargetModel } from '@/capability/program/LayerTargetModel'
import { resourceModelDefaultValueService } from '@/capability/resource/ResourceModelDefaultValueService'
import { generateLabel } from '@/capability/tower/util'
import type { LayerDto } from 'typescript-core-api-client'
import type { CarrierDto, CoverageDto } from 'typescript-core-api-client/dist/api'

import type { LayerModelConstructionAttr } from './LayerModelConstructionAttr'
import type { LayerModelMutable } from './LayerModelMutable'

/**
 * Lightweight Client-side model for a Layer
 */

const logger = getLogger('layer.LayerModelImpl')

export class LayerModelImpl implements LayerModel, LayerModelImmutable {
  'assigned'?: boolean
  'carriers'?: Array<CarrierDto>
  'coverage'?: CoverageDto
  'id'?: string
  'layerTarget'?: LayerTargetModel
  @Type(() => LayerModelImpl)
  'layers'?: Array<LayerModel>
  'name'?: string
  'percentage'?: number
  'plug'?: boolean
  'shadow'?: boolean

  constructor(attr: LayerModelConstructionAttr) {
    if (!attr) {
      logger.trace(`constructor(): attr is null`)
      return
    }

    if (Object.prototype.hasOwnProperty.call(attr, '_layerDto')) {
      logger.trace(`constructor()`, { context: { layerDto: attr._layerDto } })
      plainToClassFromExist(this, attr._layerDto)
    } else {
      Object.assign(this, {})
    }

    if (Object.prototype.hasOwnProperty.call(attr, 'id')) {
      this.id = attr.id
    }
    if (Object.prototype.hasOwnProperty.call(attr, 'limit')) {
      layerDtoUtil.assureLimitPresent(this)
      this.coverage!.limit!.amount = attr.limit
    }
    if (Object.prototype.hasOwnProperty.call(attr, 'excess')) {
      layerDtoUtil.assureExcessPresent(this)
      this.coverage!.excess!.amount = attr.excess
    }
    if (Object.prototype.hasOwnProperty.call(attr, 'percentage')) {
      this.percentage = attr.percentage
    }
    if (Object.prototype.hasOwnProperty.call(attr, 'plug')) {
      this.plug = attr.plug
    }
    if (Object.prototype.hasOwnProperty.call(attr, 'name')) {
      this.name = attr.name
    }

    resourceModelDefaultValueService.superimposeLayerDefaultsWhereUndefinedInPlace({ layer: this, recursively: true })
  }

  public getNameWithDefault(): string | undefined {
    if (!this.name || this.name === 'untitled') {
      this.name = generateLabel(this)
    }
    return this.name
  }

  public getNestedLayers(): LayerModelMutable[] | undefined {
    return this.layers as LayerModelMutable[]
  }

  public appendNestedLayer(layerModel: LayerModelMutable): void {
    this.layers = this.layers || []
    this.layers.push(plainToInstance(LayerModelImpl, layerModel))
  }

  public getNumEmbeddedLayers(): number {
    if (this.layers == null) {
      return 0
    }
    return this.layers.length
  }

  public set widthPercent(floatValue: number | undefined) {
    this.percentage = floatValue
  }

  public get widthPercent(): number | undefined {
    return this.percentage
  }

  public isWidthFractional(): boolean {
    return !(this.widthPercent == null || this.widthPercent == 100)
  }

  public set limit(value: number | undefined) {
    logger.debug('setting limit', { context: { value } })
    // value = numberUtil.tryToNumberIfPossible(value as unknown as string)
    layerDtoUtil.assureLimitPresent(this)
    this.coverage!.limit!.amount = value == null ? undefined : value
  }

  public get limit(): number | undefined {
    return this.coverage?.limit?.amount
  }

  public set _limitWithNoopSetter(value: number | undefined) {
    // no-op
  }

  public get _limitWithNoopSetter(): number | undefined {
    return this.limit
  }

  public set excess(value: number | undefined) {
    layerDtoUtil.assureExcessPresent(this)
    this.coverage!.excess!.amount = value
  }

  public get excess(): number | undefined {
    return this.coverage?.excess?.amount
  }

  public set _excessWithNoopSetter(value: number | undefined) {
    // no-op
  }

  public get _excessWithNoopSetter(): number | undefined {
    return this.excess
  }

  /**
   * Return the top of this layer
   *
   * Where top = excess + limit
   */

  public getTop(): number /* float */ | undefined {
    if (this.excess != null && this.limit != null) {
      return this.limit + this.excess
    }
    return undefined
  }

  public _getLayerDtoMutableRef(): LayerDto {
    return this
  }

  public _getLayerDtoCopy(): LayerDto {
    return cloneDeep(this)
  }

  /** The returned LayerDto is readonly reference */
  public _getLayerDtoReadonlyRef(): Readonly<LayerDto> {
    // TODO: Is this safe?
    return this as Readonly<LayerDto>
  }

  public generateProgrammaticLabel(): string {
    return generateLabel(this)
  }

  /**
   * toHuman() is intended to be used for human-friendly debugging
   * The output of toHuman() can and will change at any time for any reason.
   * Do not programmatically depend on the output of toHuman()
   */
  public toHuman(): string {
    return safeStringify({
      name: this.name,
      id: this.id,
      limit: this.limit,
      percentage: this.percentage,
      jitProgrammaticLabel: this.generateProgrammaticLabel()
    })
  }

  public getNameWithCoverageLabelFallback(): string {
    return this.name! || this.generateProgrammaticLabel()
  }

  public generateJitArrayOfSubLayerModelMutable(): LayerModelMutable[] | undefined {
    return this.layers as LayerModelMutable[]
  }

  public generateJitArrayOfSubLayerModelMutableTopMostFirst(): LayerModelMutable[] | undefined {
    return this.layers as LayerModelMutable[]
  }

  public tryGetPlugSegmentWhichIsOnTheRight(): LayerModelMutable | undefined {
    return layerDtoUtil.tryGetPlugSegmentWhichIsOnTheRight(this._getLayerDtoMutableRef()) as LayerModelMutable
  }
}
