// All these components inherit from AdminPageComponent
import type { RouteRecordRaw } from 'vue-router'

const routes: RouteRecordRaw[] = [
  {
    path: '',
    redirect: { name: 'AdminOrganizationManagement' }
  },
  {
    path: 'organizations',
    name: 'AdminOrganizationManagement',
    component: () => import('@/views/admin/AdminOrganizationManagement.vue')
  },
  {
    path: 'teams',
    alias: 'user-groups',
    component: () => import('@/views/admin/teams/AdminTeamsManagement.vue'),
    children: [
      {
        path: '',
        component: () => import('@/views/admin/teams/AdminTeamsListView/AdminTeamsListView.vue'),
        name: 'AdminTeamsListView'
      },
      {
        path: 'new',
        component: () => import('@/views/admin/teams/AdminTeamsCreateNew/AdminTeamsCreateNew.vue'),
        name: 'AdminTeamsCreateView'
      },
      {
        path: 'search-by-user',
        component: () => import('@/views/admin/teams/AdminTeamsSearchByUser/AdminTeamsSearchByUser.vue'),
        name: 'AdminTeamsSearchByUser'
      },
      {
        path: ':teamId',
        component: () => import('@/views/admin/teams/AdminTeamsDetailView/AdminTeamsDetailView.vue'),
        name: 'AdminTeamsDetailView'
      },
      {
        path: ':teamId/edit',
        component: () => import('@/views/admin/teams/AdminTeamsEditTeamView/AdminTeamsEditTeamView.vue'),
        name: 'AdminTeamsEditView'
      }
    ]
  },
  {
    path: 'geo-risk',
    name: 'AdminGeoRisk',
    component: () => import('@/views/admin/AdminGeoRisk/AdminGeoRisk.vue')
  },
  {
    path: 'templates',
    name: 'AdminTemplatesWrapper',
    component: () => import('@/views/admin/templates/AdminTemplatesWrapper.vue'),
    children: [
      {
        path: '',
        name: 'AdminTemplatesListView',
        component: () => import('@/views/admin/templates/AdminTemplatesListView/AdminTemplatesListView.vue')
      },
      {
        path: ':id',
        name: 'AdminTemplatesDetailView',
        component: () => import('@/views/admin/templates/AdminTemplatesDetailView/AdminTemplatesDetailView.vue')
      }
    ]
  },
  {
    path: 'emails',
    name: 'AdminEmailManagement',
    component: () => import('@/views/admin/AdminEmailManagement.vue')
  },
  {
    path: 'intuit',
    name: 'AdminIntuitManagement',
    component: () => import('@/views/admin/AdminIntuitManagement.vue')
  },
  {
    path: 'document-extract-queue',
    name: 'AdminDocumentExtractQueue',
    component: () => import('@/views/admin/AdminDocumentExtractQueue.vue')
  },
  {
    path: 'initial-deals',
    redirect: function () {
      return { name: 'DealQueueList' }
    }
  },
  {
    path: 'initial-deals/:dealId',
    redirect: function (to) {
      return { name: 'DealQueueDetail', params: { dealId: to.params.dealId } }
    }
  },
  {
    path: 'statement-of-values',
    component: () => import('@/views/admin/statement-of-values/AdminStatementOfValuesWrapper.vue'),
    children: [
      {
        path: '',
        name: 'AdminStatementOfValuesListView',
        component: () => import('@/views/admin/statement-of-values/AdminStatementOfValuesListView/AdminStatementOfValuesListView.vue'),
        meta: {
          title: 'Statement of Values'
        }
      },
      {
        path: 'new',
        name: 'AdminStatementOfValuesCreateView',
        component: () => import('@/views/admin/statement-of-values/AdminStatementOfValuesCreateView/AdminStatementOfValuesCreateView.vue'),
        meta: {
          title: 'Create a New Statement of Values Header'
        }
      },
      {
        path: ':headerId/update',
        name: 'AdminStatementOfValuesUpdateView',
        component: () => import('@/views/admin/statement-of-values/AdminStatementOfValuesUpdateView/AdminStatementOfValuesUpdateView.vue'),
        meta: {
          title: 'Update Statement of Values Header'
        }
      }
    ]
  },
  {
    path: 'line-items',
    component: () => import('@/views/admin/line-items/AdminLineItemsWrapper.vue'),
    children: [
      {
        path: '',
        name: 'AdminLineItemsListView',
        component: () => import('@/views/admin/line-items/AdminLineItemsListView/AdminLineItemsListView.vue'),
        meta: {
          title: 'Line Items'
        }
      },
      {
        path: 'new',
        name: 'AdminLineItemsCreateView',
        component: () => import('@/views/admin/line-items/AdminLineItemsCreateView/AdminLineItemsCreateView.vue'),
        meta: {
          title: 'Create a New Line Item'
        }
      },
      {
        path: ':lineItemId/update',
        name: 'AdminLineItemsUpdateView',
        component: () => import('@/views/admin/line-items/AdminLineItemsUpdateView/AdminLineItemsUpdateView.vue'),
        meta: {
          title: 'Update Line Item'
        }
      }
    ]
  }
]

export default routes
